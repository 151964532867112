import { observable, action, toJS } from 'mobx'
import {select} from 'd3';
 import qs from 'qs'
import { APIToken, APIFile, APIEndExt } from '../../api'
import EZUIKit from 'ezuikit-js'
import { realtimeStore, mapStore, dsc } from '../../appStore';
import { taskStore } from './../Task/TaskStore'
import { zoneStore } from './../ElecFence/ZoneStore'
import moment from 'moment' 
import {uniqBy, map as lmap} from 'lodash'
import i18n from '../../i18n' 
import { trackPlaybackStore } from '../TrackPlayback/trackPlaybackStore';
import { dataBoardStore } from './../DataBoard/dataBoardStore'
import {  closeCamera, showCamera } from './camera'
import { multiMapStore } from './../MultiMap/MultiMapStore'
import {env} from './../../env'
const appSecret = env.video_secret?env.video_secret:process.env.video_secret
const appKey = env.video_key?env.video_key:process.env.video_key

export const mapFilterStore = observable({
  selectObj: {
    orgId: 'all',
    zoneId: 'all',
    areaId: 'all',
    groupId: 'all',
    objCategoryId: 'all',
    objId: '选择定位对象',
    isSelected: false,
    zone_video_task: false,
    obj_id: '选择定位对象'//部门筛选
  },
  isUpdateData: true,//是否更新定位对象点位
  setUpdateData: action((val) => { mapFilterStore.isUpdateData = val }),
  isUpdataZone: true,
  setUpdateZone: action((val) => { mapFilterStore.isUpdataZone = val }),
  videoCheck: false,
  setVideoCheck: action((val) => { mapFilterStore.videoCheck = val }),
  selectedFloorplan: [],
  setFloorplan: action(xs => { mapFilterStore.selectedFloorplan = xs }),
  setSelectObj: action(function (val, info) { this.selectObj[val] = info }),

  valueObj: null,
  setValueObj: action(val => {
    mapFilterStore.valueObj = val
  }),
  // 新增过滤部门--定位对象--start
  department_data: [],
  setDepartment_data: action(val => {
    mapFilterStore.department_data = val
  }),
  //  新增过滤部门--定位对象--end
  data: [],
  setData: action((data) => {
    let trackable = mapFilterStore.trackableList.filter(item => {
      return item.uid === data.dev_id
    })
    let data_index = mapFilterStore.data.findIndex(item => item.dev_id === data.dev_id)
    let filter_data = Object.assign(data_index === -1 ? data : mapFilterStore.data[data_index], trackable[0])
    // console.log(filter_data)
    if (data_index === -1) {
      mapFilterStore.data.push(Object.assign(filter_data, { tail_points: [] }));
    } else {
      mapFilterStore.data[data_index] = filter_data
    }
  }),
  // setData: action((data) => {
  //   let trackable = mapFilterStore.trackableList.filter(item => {
  //     return item.uid === data.dev_id
  //   })
  //   let filter_data = Object.assign(data, trackable[0])
  //   mapFilterStore.data.push(filter_data);
  // }),
  setRestData: action(val => {
    mapFilterStore.data = val
  }),
  setDataViolate: action((i, info, val) => {
    // console.log(info)
    mapFilterStore.data[i][info] = val
  }),//定位对象预警 
  setDataFliter: action((i, val) => { mapFilterStore.data[i] = val }),
  setDataRemove: action((index) => {
    let data = mapFilterStore.data
    data.splice(index, 1)
    mapFilterStore.data = data
  }),
  objList: [],
  setObjlist: action(val => {
    mapFilterStore.objList = val
  }),
  zone_data: [],
  setZoneData: action((val) => {
    mapFilterStore.zone_data = val;
  }),
  setZoneDataViolate: action((i, info, val) => {
    mapFilterStore.zone_data[i][info] = val
  }),
  updatableChart: null,
  setUpdatableChart: action((val) => {
    mapFilterStore.updatableChart = val;
  }),

  // updatableChartTrack: null,//轨迹
  // setUpdatableChartTrack: action((val) => { mapFilterStore.updatableChartTrack = val; }),
  floor_sublocation_uid: null,//选中楼层  
  setFloorSubUid: action((val) => { mapFilterStore.floor_sublocation_uid = val }),
  openVideo: false,
  setOpenVideo: action((val) => { mapFilterStore.openVideo = val }),
  currentZone: '',
  setCurrentZone: action((val) => { mapFilterStore.currentZone = val }),
  video_zone_data: [],//视频区域筛选 
  setVideo_zone_data: action(val => { mapFilterStore.video_zone_data = val }),
  objIdCurrent: '选择定位对象',
  setObjIdCurrent: action(val => { mapFilterStore.objIdCurrent = val }),
  zone_data_filter: [],//围栏筛选  实时地图 部门、规则、区域类型过滤后
  setZoneDataFilter: action(val => { mapFilterStore.zone_data_filter = val }),
  trackableList: [],
  setTrackableList: action(val => { mapFilterStore.trackableList = val }),
  zoneAll: [],//所有围栏跟对象   
  setZoneAll: action(val => { mapFilterStore.zoneAll = val }),
  openTrack: false,
  setOpenTrack: action(val => {
    mapFilterStore.openTrack = val
  }),
  objSelect: '',
  setObjSelect: action(val => {
    mapFilterStore.objSelect = val
  }),
  onlineNum: 0,
  setOnlineNum: action(val => {
    mapFilterStore.onlineNum = val
  }),
  groupColor: {},
  setGroupColor: action(val => {
    mapFilterStore.groupColor = Object.assign(mapFilterStore.groupColor, val)
  }),
  // allFloor: [],//所有楼层sublocation
  // setAllFloor: action(val => {
  //   mapFilterStore.allFloor = val
  // }),
  // setAllFloorWarn: action((i, info, val) => {
  //   mapFilterStore.allFloor[i][info] = val
  // }), 
  allFloorplans: [],//所有楼层信息floorplans
  setAllFloorplans: action(val => {
    val = val.map(item => {
      let index = mapFilterStore.allFloorplans.findIndex(floor => floor.sublocation_uid === item.sublocation_uid)
      return Object.assign(item, mapFilterStore.allFloorplans[index])
    })
    mapFilterStore.allFloorplans = val
  }),
  setFloorInfo: action((i, info, val) => {//修改allFloorplans中信息
    mapFilterStore.allFloorplans[i][info] = val
  }),

  selected_sublocation_uid: null,//实时地图当前选中楼层sublocation_uid
  setSelectSubuid: action(val => {
    mapFilterStore.selected_sublocation_uid = val
  }),
  selected_sub_uid_track: 'none',//轨迹回放选中楼层
  setSelected_sub_uid_track: action(val => {
    mapFilterStore.selected_sub_uid_track = val
  }),

  // selectedFloorplan_track: null,////轨迹回放选中楼层信息
  // setFloorplan_track: action(xs => { mapFilterStore.selectedFloorplan_track = xs }),
  audioMap: null,
  setAudioMap: action(val => {
    mapFilterStore.audioMap = val
  }),

  isZoneName: false,
  setIsZoneName: action(val => {
    mapFilterStore.isZoneName = val
  }),
  alignment: '',
  setAlignment: action(val => {
    mapFilterStore.alignment = val
  }),
  // sublocation_uid: '',
  // setSublocationUid: action(val => {
  //   mapFilterStore.sublocation_uid = val
  // }),
  // 当前选中的svg
  sublocation_svg: null,
  setSublocationSvg: action(val => {
    mapFilterStore.sublocation_svg = val
  }),
  loadSvg: false,//加载svg
  setLoadSvg: action(val => {
    mapFilterStore.loadSvg = val
  }),
  showName: false,
  setShowName: action(val => {//实时地图姓名展示
    mapFilterStore.showName = val
  }),
  showRole: false,
  setShowRole: action(val => {//实时地图角色展示
    mapFilterStore.showRole = val
  }),
  // 全迹视频点呼跟语音点呼
  call_data: [],
  setCallData: action(val => {
    mapFilterStore.call_data = val
  }),
  call_open: false,
  setCallOpen: action(val => {
    mapFilterStore.call_open = val
  }),
  call_dev: null,
  setCallDev: action(val => {
    mapFilterStore.call_dev = val
  }),
  user_info: [],
  setUserInfo: action(val => {
    mapFilterStore.user_info = val
  })

})

// 围栏筛选
const fileterData = ({ org, zone, area }) => {
  mapFilterStore.setSelectObj('groupId', 'all')
  mapFilterStore.setSelectObj('objCategoryId', 'all')
  mapFilterStore.setSelectObj('objId', '选择定位对象')
  mapFilterStore.setSelectObj('isSelected', true)
  mapFilterStore.setSelectObj('zone_video_task', false)
  mapFilterStore.setVideoCheck(false)

  zone === 'all' && area === 'all' && org === 'all' ?
    mapFilterStore.setUpdateData(true) : mapFilterStore.setUpdateData(false)
  zone === 'all' && area === 'all' && org === 'all' ?
    mapFilterStore.setUpdateZone(true) : mapFilterStore.setUpdateZone(false)

  let zonedata = mapFilterStore.zone_data.filter(item => {
    if (org !== 'all') { return item['dep'] === Number(org) } else { return true }
  })
    .filter(item => {
      if (zone !== 'all') {

        return item['schema_def_id'] === Number(zone)
      }
      else { return true }
    })
    .filter(item => {
      if (area !== 'all') {
        return item['zone_type_name'] === area
      } else { return true }
    })
  mapFilterStore.updatableChart.zone_data(zonedata)

  if (mapFilterStore.isZoneName) mapFilterStore.updatableChart.zoneName_data(zonedata)
  // let data_point = filterPoint(zonedata, mapFilterStore.data)
  let data_point = mapFilterStore.data.filter(item => {
    if (org !== 'all') {
      return item['dep'] === Number(org) && item.slid === mapFilterStore.floor_sublocation_uid
    } else {
      return item.slid === mapFilterStore.floor_sublocation_uid
    }
  })
  mapFilterStore.updatableChart.data(uniqBy(data_point, 'dev_id'))
  mapFilterStore.updatableChart.name_data(uniqBy(data_point, 'dev_id'))
  // mapFilterStore.setOnlineNum(toJS(data_point).length)
  mapFilterStore.setOnlineNum(uniqBy(data_point, 'dev_id').length)

  mapFilterStore.setZoneDataFilter(zonedata)
}
// 定位对象组跟定位对象类别筛选
const filterObj = (id, groupId) => {
  mapFilterStore.setSelectObj('orgId', 'all')
  mapFilterStore.setSelectObj('zoneId', 'all')
  mapFilterStore.setSelectObj('areaId', 'all')
  mapFilterStore.setSelectObj('zone_video_task', false)
  mapFilterStore.setSelectObj('isSelected', true)
  mapFilterStore.setVideoCheck(false)
  mapFilterStore.updatableChart.zone_data(mapFilterStore.zone_data)
  id === 'all' && groupId === 'all' ? mapFilterStore.setUpdateData(true) : mapFilterStore.setUpdateData(false)
  let res = mapFilterStore.data.filter(item => {
    if (id !== 'all') {
      return item.schema_def_id === Number(id) && item.slid === mapFilterStore.floor_sublocation_uid
    }
    else {
      return item.slid === mapFilterStore.floor_sublocation_uid
    }
  })
    .filter(item => {
      if (groupId !== 'all') {
        return item.groups !== null && item.groups.includes(groupId) && item.slid === mapFilterStore.floor_sublocation_uid
      } else {
        return item.slid === mapFilterStore.floor_sublocation_uid
      }
    })
  mapFilterStore.updatableChart.data(uniqBy(res, 'dev_id'))
  mapFilterStore.updatableChart.name_data(uniqBy(res, 'dev_id'))
  mapFilterStore.setOnlineNum(uniqBy(res, 'dev_id').length)
}

// 视频区域筛选
const taskFilter = (checked) => {
  mapFilterStore.setSelectObj('orgId', 'all')
  mapFilterStore.setSelectObj('zoneId', 'all')
  mapFilterStore.setSelectObj('areaId', 'all')
  mapFilterStore.setSelectObj('groupId', 'all')
  mapFilterStore.setSelectObj('objCategoryId', 'all')
  mapFilterStore.setSelectObj('objId', '选择定位对象')

  mapFilterStore.setSelectObj('zone_video_task', checked)
  mapFilterStore.setVideoCheck(checked)
  let zonedata
  if (checked) {
    mapFilterStore.setSelectObj('isSelected', true)
    mapFilterStore.setUpdateZone(false)
    mapFilterStore.setUpdateData(false)
    zonedata = mapFilterStore.zone_data.filter((item) => {
      return item.camera_track === true
    })
    mapFilterStore.updatableChart.zone_data(zonedata)
    mapFilterStore.setZoneDataFilter(zonedata)
    if (mapFilterStore.isZoneName) mapFilterStore.updatableChart.zoneName_data(zonedata)
  } else {
    mapFilterStore.setSelectObj('isSelected', false)
    mapFilterStore.setUpdateZone(true)
    mapFilterStore.setUpdateData(true)
    mapFilterStore.updatableChart.zone_data(mapFilterStore.zone_data)
    if (mapFilterStore.isZoneName) mapFilterStore.updatableChart.zoneName_data(mapFilterStore.zone_data)

  }
  mapFilterStore.setVideo_zone_data(zonedata)

}

// 云视频联动--打开
async function gettoken(url) {
  if (mapStore.player === null) {
    let params = qs.stringify({
      appSecret: appSecret,
      appKey: appKey
      // appSecret: '3ab63a679450b3aba794150f58b49a4b',
      // appKey: '493a2716b4b04ec1bd0dcb0121520568'
    })
    let videotoken = await APIToken.post('/api/lapp/token/get', params)
    let player = new EZUIKit.EZUIKitPlayer({
      id: 'video-container', // 视频容器ID  
      accessToken: videotoken.data.data.accessToken,
      url: url
    })
    mapStore.setPlayer(player)
  } else {
    mapStore.player.play({
      url: url,
    })
  }
}
// 云视频联动--关闭
function CloseVideo() {

  // if(cameraStore.web_rtc){
  //   console.log(cameraStore.web_rtc)
  //   cameraStore.web_rtc.then(res => {  
  //     res.close && res.close();  
  //   })
  // }  
  // cameraStore.web_rtc.close&&cameraStore.web_rtc.close()
  // mapStore.player.stop()
  closeCamera()
  select('#video-box').style('display', 'none')
  mapFilterStore.setSelectObj('objId', '选择定位对象')
  mapFilterStore.setOpenVideo(false)
  mapFilterStore.setObjIdCurrent('选择定位对象')
  mapFilterStore.setCurrentZone('')
}

// 进入页面以及tab切换初始化
function initData() {
  mapFilterStore.setSelectObj('orgId', 'all')
  mapFilterStore.setValueObj(null)
  mapFilterStore.setSelectObj('zoneId', 'all')
  mapFilterStore.setSelectObj('areaId', 'all')
  mapFilterStore.setSelectObj('groupId', 'all')
  mapFilterStore.setSelectObj('objCategoryId', 'all')
  mapFilterStore.setSelectObj('objId', '选择定位对象')
  mapFilterStore.setSelectObj('isSelected', false)
  mapFilterStore.setSelectObj('zone_video_task', false)
  mapFilterStore.setUpdateData(true)

  mapFilterStore.zone_data.map((item, index) => {
    mapFilterStore.setZoneDataViolate(index, 'rdp_violate', false)
  })
  mapFilterStore.allFloorplans.map((item, index) => {
    // mapFilterStore.setAllFloorWarn(index, 'floor_violate', false) 
    mapFilterStore.setFloorInfo(index, 'floor_violate', false)
  })
}

// 围栏对象监听
function posMessage() {
  // // 围栏对象告警监听
  // realtimeStore.realtime_violates.on('entry-added', async (entry) => {
  //   // console.log('围栏预警')
  //   let record = dsc.record.getRecord(entry)
  //   await record.whenReady()
  //   let data = record.get()
  //   data && zone_warning(data, true)
  // })

  // realtimeStore.realtime_violates.on('entry-removed', async (entry) => {
  //   // console.log('围栏移除')
  //   let record = dsc.record.getRecord(entry)
  //   await record.whenReady()
  //   let data = record.get()
  //   data && zone_warning(data, false)
  // })

  // 摄像头追踪监听
  realtimeStore.realtime_zoneinout.on('entry-added', async (entry) => {
    // console.log('视频监听---set')
    let record = dsc.record.getRecord(entry)
    await record.whenReady()
    if (mapFilterStore.objIdCurrent !== '选择定位对象') {
      // console.log('视频监听---set')
      videoUpdate(record.get(), true)
    }
  })

  realtimeStore.realtime_zoneinout.on('entry-removed', async (entry) => {
    // console.log('视频监听---clear')
    let record = dsc.record.getRecord(entry)
    await record.whenReady()
    if (mapFilterStore.objIdCurrent !== '选择定位对象') {
      videoUpdate(record.get(), false)
    }
  })






}

export function objUpdate() {
  // 定位对象点位移动更新
  let obj_point = realtimeStore.liveTkos.getEntries()
  obj_point.map(async item => {
    let record = dsc.record.getRecord(item)
    await record.whenReady()
    record.subscribe(obj_info => {
      // console.log('标签移动')
      if (obj_info.slid === mapFilterStore.floor_sublocation_uid) {
        let testRes = mapFilterStore.data.findIndex(item => item.dev_id === obj_info.dev_id)
        if (testRes !== -1) {
          let obj = Object.assign(mapFilterStore.data[testRes], obj_info)

          mapFilterStore.setDataFliter(testRes, obj)

          initObjData()
        }
      }
      if (window.location.pathname !== '/realtime_map' && window.location.pathname !== '/data_board') {
        record.discard()
      }
    })

  })
}
// 围栏预警更新
function violateUpdate(record, isWarn) {
  let testResZone = mapFilterStore.zone_data.findIndex(item => item.uid === record.rule_zone_uid)
  let testRes = mapFilterStore.data.findIndex(item => item.dev_id === record.dev_id)
  // 围栏预警  
  if (testResZone !== -1) {
    if (record.rule_name !== 'halt' && record.rule_name !== 'detain') {
      mapFilterStore.setZoneDataViolate(testResZone, 'rdp_violate', isWarn)

      // 实时地图
      if (record.sublocation_uid === mapFilterStore.floor_sublocation_uid && window.location.pathname === "/realtime_map") {
        mapFilterStore.updatableChart && mapFilterStore.updatableChart.zone_data(mapFilterStore.zone_data)
        if (mapFilterStore.isZoneName && mapFilterStore.updatableChart) mapFilterStore.updatableChart.zoneName_data(mapFilterStore.zone_data)
      }

      //  九宫格
      if (window.location.pathname === "/multi_map") {
        let uids = lmap(multiMapStore.mapList, "sublocation_uid")
        if (uids.includes(record.sublocation_uid)) {
          let info = multiMapStore.mapList.find(item => item.sublocation_uid === record.sublocation_uid)
          info.updatableChart.zone_data(info.zone_data)
        }
      }

    }
    if (testRes !== -1) {
      mapFilterStore.setDataViolate(testRes, 'rdp_violate', isWarn)
      // console.log('围栏预警更新')
      initObjData()
    }

  }

}
// 楼层tab预警更新
function tabFlash(record, isWarn) {
  if (isWarn) {
    let floorId = mapFilterStore.allFloorplans.findIndex(item => item.sublocation_uid === record.sublocation_uid)
    if (floorId !== -1 && !mapFilterStore.allFloorplans[floorId]['floor_violate']) {
      // mapFilterStore.setAllFloorWarn(floorId, 'floor_violate', true)
      mapFilterStore.setFloorInfo(floorId, 'floor_violate', true)
    } else {
      return
    }
  } else {
    let filterFloor = realtimeStore.violates.filter(item => {
      return item.sublocation_uid === record.sublocation_uid
    })

    if (filterFloor.length === 0) {
      let floorId = mapFilterStore.allFloorplans.findIndex(item => item.sublocation_uid === record.sublocation_uid)
      if (floorId !== -1) {
        // mapFilterStore.setAllFloorWarn(floorId, 'floor_violate', false)
        mapFilterStore.setFloorInfo(floorId, 'floor_violate', false)
      }
    } else {
      return
    }
  }
}

// 摄像头追踪更新
function videoUpdate(record, isWarn) {
  // console.log(toJS(record))
  // console.log(mapFilterStore.objIdCurrent)
  if (mapFilterStore.objIdCurrent !== '选择定位对象' && record.dev_id === mapFilterStore.objIdCurrent) {
    // console.log('标签存在')
    // console.log(isWarn)
    if (isWarn) {
      // console.log(toJS(mapFilterStore.zone_data))
      // var videoInfo = filterPoint(mapFilterStore, mapFilterStore.zone_data, mapFilterStore.objIdCurrent)[0]
      var videoInfo = mapFilterStore.zone_data.filter(item => item.uid === record.zone)[0]
      // console.log(videoInfo)
      if (videoInfo && videoInfo.camera_url !== null) {
        // console.log('摄像头打开')
        select('#video-box').style('display', 'flex')
        mapFilterStore.setCurrentZone(videoInfo.name)
        // gettoken(videoInfo.camera_url)
        showCamera(videoInfo.camera_url)
      }
    } else {
      if (mapStore.player) {
        mapStore.player.stop()
      }
      closeCamera()
      select('#video-box').style('display', 'none')
    }

  }
}



// 出来预警围栏--包含缺员预警
export function zone_warning(data, isWarn) {
  let dataInfo = data
  if (data.rule_name === "understaffing") {
    // console.log(toJS(data))
    // console.log(toJS(mapFilterStore.zoneAll))
    let info = mapFilterStore.zoneAll.find(item => item.uid === data.rule_zone_uid)
    // console.log(toJS(info))
    dataInfo = Object.assign(data, { sublocation_uid:info.sublocation_uid })
  }
  // if (dataInfo.sublocation_uid === mapFilterStore.floor_sublocation_uid) {
  violateUpdate(dataInfo, isWarn)
  // }
  window.location.pathname === "/realtime_map" && tabFlash(dataInfo, isWarn)
}

// 实时地图--初始化楼层地图及信息--start
async function init_realTime_Echart() {
  let violates = realtimeStore.realtime_violates.getEntries()
  for (var i = 0; i < violates.length; i++) {
    let record = dsc.record.getRecord(violates[i])
    await record.whenReady()
    let data = record.get()
    data && zone_warning(data, true)
  }
  initObjData()
  // console.log('初始化')
}
// 实时地图--初始化楼层地图及信息--end

//实时地图--更新定位对象位置信息--start 
function initObjData() {
  // 楼层过滤后的对象
  // console.log(toJS(mapFilterStore.data))
  let filter_data = mapFilterStore.data.filter(item => {
    return item.slid === mapFilterStore.floor_sublocation_uid
  })
  // console.log(toJS(filter_data))
  // 数据看板--标签位置更新
  if (window.location.pathname === '/data_board' && dataBoardStore.updatableChart) {
    dataBoardStore.updatableChart.data(uniqBy(filter_data, 'dev_id'))
  }
  // 实时地图--标签位置更新
  if (window.location.pathname === '/realtime_map' && mapFilterStore.updatableChart) {
    mapFilterStore.updatableChart.data(uniqBy(filter_data, 'dev_id'))
    mapFilterStore.updatableChart.name_data(uniqBy(filter_data, 'dev_id'))
    mapFilterStore.setObjlist(filter_data)
    // console.log(uniqBy(filter_data, 'dev_id').length)
    mapFilterStore.setOnlineNum(uniqBy(filter_data, 'dev_id').length)
  }
}
//实时地图--更新定位对象位置信息--end

// 实时地图--预警闪烁---start 
function getAudioMap(violates) {
  if (violates > 0) {
    if (!mapFilterStore.audioMap) mapFilterStore.setAudioMap(new Audio('/alarm.mp3'))
    mapFilterStore.audioMap.play()
    mapFilterStore.audioMap.loop = 'loop'
  } else {
    if (mapFilterStore.audioMap) mapFilterStore.audioMap.pause()
  }
}
// 实时地图--预警闪烁---end 

// 实时地图---tab楼层切换---初始楼层及数据---start 
export const initTabEcharts = async (newAlignment) => {
  mapFilterStore.setIsZoneName(false)
  mapFilterStore.setAlignment(newAlignment);
  let floor_plan = mapFilterStore.allFloorplans.find(item => item.sublocation_name === newAlignment)
  let uid = floor_plan['uid']
  mapFilterStore.setSelectSubuid(uid)
  mapFilterStore.setSelected_sub_uid_track(uid)

  mapFilterStore.setFloorSubUid(floor_plan.sublocation_uid)
  mapFilterStore.setFloorplan(floor_plan)
  trackPlaybackStore.setSelectFloorInfo(floor_plan)


  // 切换楼层底图svg
  mapFilterStore.setSublocationSvg(floor_plan)

  // 过滤围栏  
  let zoneList = mapFilterStore.zoneAll.filter(item => { return item.sublocation_uid === floor_plan.sublocation_uid })

  mapFilterStore.setZoneData(zoneList)
}
// 实时地图---tab楼层切换---初始楼层及数据---end 


function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      resolve(e.target.result);
    };
    // readAsDataURL
    fileReader.readAsDataURL(blob);
    fileReader.onerror = () => {
      reject(new Error('blobToBase64 error'));
    };
  });
}


export function svgToDataURL(svg) {
  return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svg)))}`
}
export function setSvgDim(svgString) {
  const parser = new DOMParser()
  const svgDoc = parser.parseFromString(svgString, "text/html").getElementsByTagName('svg')
  const svgElement = svgDoc[0]
  // 临时写到dom
  let svg_instance = window.document.body.appendChild(svgElement)
  // 拿到bbox
  let bbox = svg_instance.getBBox()
  // console.log(bbox)

  // 写回到svg string
  svgElement.setAttribute('height', bbox.height)
  svgElement.setAttribute('width', bbox.width)
  const serializer = new XMLSerializer()
  let changed_string = serializer.serializeToString(svgElement)
  // 从dom移除
  window.document.body.removeChild(svgElement)
  return { width: bbox.width, height: bbox.height, svg: changed_string }
  // return changed_string
}

// 下载更新svg
export async function downLoadSvg(floor_index, menu) {
  if (mapFilterStore.allFloorplans.length === 0) {
    let sublocations_data = await APIEndExt.get('/floorplans')
    if (sublocations_data.data.length === 0) return
    mapFilterStore.setAllFloorplans(sublocations_data.data)
  }
  let floor_info = mapFilterStore.allFloorplans[floor_index]
  // 获取楼层图片信息
  let sublocations_data_EndExt = await APIEndExt.get('/sublocations')
  let floorId = sublocations_data_EndExt.data.findIndex(every => every.uid === floor_info.sublocation_uid)

  if (floor_info['twod_map_file_name'] !== sublocations_data_EndExt.data[floorId]['twod_map_file_name']) {
    floor_info = Object.assign(floor_info, {
      twod_map_file_name: sublocations_data_EndExt.data[floorId]['twod_map_file_name'],
      isUpdate: false
    })
    mapFilterStore.setFloorInfo(floor_index, 'isUpdate', false)
    mapFilterStore.setFloorInfo(floor_index, 'twod_map_file_name', sublocations_data_EndExt.data[floorId]['twod_map_file_name'])

  }
  // isUpdate为false以及twod_map_file_name名字存在，即下载svg图
  if ((!floor_info.isUpdate && floor_info.twod_map_file_name != null)) {
    mapFilterStore.setFloorInfo(floor_index, 'isUpdate', true)
    mapFilterStore.setLoadSvg(true)
    await APIFile.get(`/map_files?file_name=${floor_info.twod_map_file_name}`
      , { responseType: 'blob' }
    ).then(async res => {
      let blob = new Blob([res.data], { type: "image/svg+xml" });
      let url = window.URL.createObjectURL(blob);
      // 热图数据
      let heat_map = await APIFile.get(`/map_files?file_name=${floor_info.twod_map_file_name}`)

      let { width, height, svg } = setSvgDim(heat_map.data)
      let floorId = mapFilterStore.allFloorplans.findIndex(every => every.uid === floor_info.uid)

      mapFilterStore.setFloorInfo(floorId, 'twod_map_svg', url)
      mapFilterStore.setFloorInfo(floorId, 'heat_map_svg', svgToDataURL(svg))

      mapFilterStore.setFloorInfo(floorId, 'heat_map_width', width)
      mapFilterStore.setFloorInfo(floorId, 'heat_map_height', height)

      menu === 'map' ? mapFilterStore.setSublocationSvg(floor_info) :
        menu === 'design' ? zoneStore.setSelectFloor(mapFilterStore.allFloorplans[floorId]) :
          menu === 'task' ? taskStore.setTaskSublocationSvg(mapFilterStore.allFloorplans[floorId]) :
            menu === 'dataBoard' ? dataBoardStore.setSublocationSvg(mapFilterStore.allFloorplans[floorId]) :
              trackPlaybackStore.setTrackSublocationSvg(mapFilterStore.allFloorplans[floorId])
      // menu === 'map' ? mapFilterStore.setSublocationSvg(mapFilterStore.allFloorplans[floorId]) : menu === 'design' ? zoneStore.setSelectFloor(mapFilterStore.allFloorplans[floorId]) : menu === 'task' ? taskStore.setTaskSublocationSvg(mapFilterStore.allFloorplans[floorId]) : menu === 'dataBoard' ? dataBoardStore.setSublocationSvg(mapFilterStore.allFloorplans[floorId]) : trackPlaybackStore.setTrackSublocationSvg(mapFilterStore.allFloorplans[floorId])
      mapFilterStore.setLoadSvg(false)
    })
  } else {
    menu === 'map' ? mapFilterStore.setSublocationSvg(floor_info) :
      menu === 'design' ? zoneStore.setSelectFloor(floor_info) :
        menu === 'task' ? taskStore.setTaskSublocationSvg(floor_info) :
          menu === 'dataBoard' ? dataBoardStore.setSublocationSvg(floor_info) :
            trackPlaybackStore.setTrackSublocationSvg(floor_info)
  }

}

{/* 实时地图功能修改--新增定位对象筛选--start */ }

export const fileter_department_data = (org) => {//部门筛选过滤
  mapFilterStore.setSelectObj('objId', '选择定位对象')
  mapFilterStore.setValueObj(null)

  filter_violate(false)
  filter_obj_update()

  let objdata = mapFilterStore.data.filter(item => {
    if (org !== 'all') {
      return item['dep'] === Number(org)
    } else { return true }
  })
  mapFilterStore.setDepartment_data(objdata)
}
{/* 实时地图功能修改--新增定位对象筛选--end */ }
// 实时地图---定位对象过滤---start 
export const filter_obj = (dev_id) => {
  let testRes = mapFilterStore.data.findIndex(item => item.dev_id === dev_id)
  if (testRes !== -1) {
    // 设置标签闪烁 
    filter_violate(true, dev_id)
    let floorInfo = mapFilterStore.allFloorplans.find(item => item.sublocation_uid === mapFilterStore.data[testRes].slid)
    // 所选标签不在当前楼层
    if (floorInfo && floorInfo.sublocation_name !== mapFilterStore.alignment) {
      initTabEcharts(floorInfo.sublocation_name)
    } else {
      filter_obj_update()
    }
  } else {
    filter_violate(false)
    filter_obj_update()
  }
}

export function filter_violate(violate, dev_id) {
  if (violate) {
    mapFilterStore.data.map((item, index) => {
      if (item.dev_id === dev_id) {
        mapFilterStore.setDataViolate(index, 'rdp_fliter_violate', true)
      } else {
        mapFilterStore.setDataViolate(index, 'rdp_fliter_violate', false)
      }
    })
  } else {
    mapFilterStore.data.map((item, index) => {
      mapFilterStore.setDataViolate(index, 'rdp_fliter_violate', false)
    })
  }
}

// console.log('更新当前楼层定位对象')  
export function filter_obj_update() {
  let filter_data = mapFilterStore.data.filter(item => {
    return item.slid === mapFilterStore.floor_sublocation_uid
  })
  if (mapFilterStore.updatableChart) {
    mapFilterStore.updatableChart.data(uniqBy(filter_data, 'dev_id'))
    mapFilterStore.updatableChart.name_data(uniqBy(filter_data, 'dev_id'))
  }
}

// 实时地图---定位对象过滤---end 
var dates = {}
dates[i18n.t('今天')] = { 
  start:moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
  end:moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
}
dates[i18n.t('昨天')] = { 
  start: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
  end: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss')
}
dates[i18n.t('本周至今')] = { 
  start: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
  end: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
}
dates[i18n.t('本月至今')] = { 
  start: moment().startOf('months').format('YYYY-MM-DD HH:mm:ss'),
  end: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
}
dates[i18n.t('自定义')] = {
  start: '',
  end: ''
}

function get_user_info() {//获取标签表单信息
  APIEndExt.get('/get_user_info')
    .then(res => {
      mapFilterStore.setUserInfo(res.data)
    })
}
async function download_avatar(info) { 
  let res_url = await APIFile.get(`/images?file_name=${info.user_avatar}`, { responseType: 'blob' })
  let blob = new Blob([res_url.data]);
  let url = window.URL.createObjectURL(blob);
  return url
}
export { fileterData, filterObj, taskFilter, CloseVideo, initData, initObjData, posMessage, gettoken, dates, init_realTime_Echart, violateUpdate, getAudioMap, download_avatar, get_user_info }
