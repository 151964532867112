import { observable, action,flow } from 'mobx'
import i18n from '../../i18n'
import { coordinate_calculatino } from './../../appStore'
import { APIEndQj } from './../../api' 
import {findIndex} from 'lodash'

export const zoneStore = observable({
  //   setUpdatableChart: action((val) => { zoneStore.updatableChart = val; }),
  select_floor_uid: null,
  setSelectFloorUid: action((val) => { zoneStore.select_floor_uid = val }),
  select_floor: null,
  setSelectFloor: action((val) => { zoneStore.select_floor = val }),
  floor_plans: [],
  setFloorPlan: action(val => { 
    zoneStore.floor_plans = val
  }),
  floor_sublocation: [],
  setFloorSublocation: action(val => {
    zoneStore.floor_sublocation = val
  }),
  mapValue: null,
  setMapValue: action(val => {
    zoneStore.mapValue = val
  }),
  image_scale: 0,
  setImageScale: action(val => {
    zoneStore.image_scale = val
  }),
  current_floor: null,//当前选中楼层
  setCurrentFloor: action(val => {
    zoneStore.current_floor = val
  }),
  baseMapWidth: 0,
  baseMapWidthHalf: 0,
  setBaseMapWidth: action(val => {
    zoneStore.baseMapWidthHalf = val / 2
    // zoneStore.baseMapWidth = val
  }),
  baseMapHeight: 0,
  baseMapHeightHalf: 0,
  setBaseMapHeight: action(val => {
    zoneStore.baseMapHeightHalf = val / 2
    // zoneStore.baseMapHeight = val
  }),
  zone_type_id: 3,
  setZoneTypeId: action(val => {
    zoneStore.zone_type_id = val
  }),
  current_sublocation_uid: '',
  setSublocationUid: action(val => {
    zoneStore.current_sublocation_uid = val
  }),
  isNew: false,
  setIsNew: action(val => {
    zoneStore.isNew = val
  }),
  position: [],
  setPosition: action(val => {
    zoneStore.position = val
  }),
  selectedLayer: null,
  setSelectedLayer: action(val => {
    zoneStore.selectedLayer = val
  }),
  initSchemaId: 0,
  setInitSchemaId: action(val => {
    zoneStore.initSchemaId = Number(val)
  }),
  schemaId: 0,//电子围栏类别
  setSchemaId: action(val => {
    zoneStore.schemaId = val
  }),
  // 全迹
  qj_data: [],
  setQjData: action(val => {
    zoneStore.qj_data = val
  }),
  qj_select: null,
  setQjSelect: action(val => {
    zoneStore.qj_select = val
  }),
  checkedAll: true,//全部check
  setCheckedAll: action(val => {
    zoneStore.checkedAll = val
  }),
  zone_category_rule: [],//围栏类别 围栏管理规则
  setZoneCategoryRule: action(val => {
    zoneStore.zone_category_rule = val
  }),
  setZoneChecked: action((i, info, val) => {
    // let i = zoneStore.zone_category_rule.indexOf(value)
    zoneStore.zone_category_rule[i][info] = val
  }),
  zoneCategory: [],//围栏类别
  setZoneCategory: action(val => {
    zoneStore.zoneCategory = val
  }),
  zoneRule: [],//围栏管理规则
  setZoneRule: action(val => {
    zoneStore.zoneRule = val
  }),
  // 围栏设计checkout
  zoneFilter: [],
  setZoneFilter: action(val => {
    zoneStore.zoneFilter = val
  }),
  addZoneFilter: action(function (value) {
    let index = findIndex(zoneStore.zoneFilter, function (o) { return o.schema_def_id === value.schema_def_id }); 
    if (index === -1) {
      zoneStore.zoneFilter.push(value)
    }
  }),
  removeZoneFilter: action(function (value) {
    let index = findIndex(zoneStore.zoneFilter, function (o) { return o.schema_def_id === value.schema_def_id });
    zoneStore.zoneFilter.splice(index, 1)
  }),
})

export const zoneFactory = function (
  {
    uid = "",
    name = "",
    project_uid = "",
    schedule = {},
    sublocation_uid = "",
    default_allowed = false,
    zone_type_name = "information",
    zone_alarm_level = '',  //警告级别
    zone_exceptions = [],
    zone_exceptions_groups = [],
    active = false,
    zone_mode = "DEACTIVATED",
    custom_info = "",
    zone_category_id = 0,
    category_form_data = null
  } = {}
) {
  const zone = observable({
    uid: uid,
    name: name,
    setName: action(function (payload) {
      zone.name = payload
    }),
    project_uid: project_uid,
    schedule: schedule,
    get startTime() {
      return encodeTime(zone.schedule.start_time)
    },
    get stopTime() {
      return encodeTime(zone.schedule.stop_time)
    },
    setStart: action(function (time) {
      zone.schedule.start_time = decodeTime(time)
    }),
    setStop: action(function (time) {
      zone.schedule.stop_time = decodeTime(time)
    }),
    sublocation_uid: sublocation_uid,
    default_allowed: default_allowed,
    setDefaultAllow: action(function (logic) {
      zone.default_allowed = logic
    }),
    zone_type_name: zone_type_name,
    category_form_data: category_form_data,
    zone_category_id: zone_category_id,
    setZoneCategoryId: action(val => {
      zone.zone_category_id = val
    }),
    zone_alarm_level: zone_alarm_level,
    setAlarmLvl: action(function (lvl) {
      zone.zone_alarm_level = lvl
    }),
    zone_exceptions: zone_exceptions,
    zone_exceptions_groups: zone_exceptions_groups,
    get zone_exceptions_merged() {
      let merged = [
        ...zone.zone_exceptions,
        ...zone.zone_exceptions_groups
      ]
      return merged
    },
    setZoneExceptions: action(function (payload) {
      zone.zone_exceptions = payload
    }),
    setZoneExceptionsGroups: action(function (payload) {
      zone.zone_exceptions_groups = payload
    }),
    active: active,
    zone_mode: zone_mode,
    setZoneMode: action(function (payload) {
      zone.zone_mode = payload
    }),
    toggleActive: action(function () {
      zone.active = !zone.active
    }),
    custom_info: custom_info,
    setCustomInfo: action(function (payload) {
      zone.custom_info = payload
    }),
  })
  return zone
}


export function create_UUID() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

export function encodeTime(aString) {
  let result = "00:00:00"
  let time = aString

  result = time ? time.substring(0, 5) : result
  result = result.substring(0, 2)
    + ":"
    + result.substring(3, result.length)
    + ":00"

  return result = new Date("1970-01-01 " + result)
}

export const zone_type_list = [
  { zone_type_id: 'null', name: i18n.t('无') },
  { zone_type_id: 'filter_in', name: i18n.t('出界检测') },
  { zone_type_id: 'filter_out', name: i18n.t('进入检测') }]

export const trans_words = (title) => {
  switch (title) {
    case '缺员人数':
      return i18n.t('缺员人数')
      break;
    case '聚众人数':
      return i18n.t('聚众人数')
      break;
    case '静止时间':
      return i18n.t('静止时间')
      break;
    case '滞留时间':
      return i18n.t('滞留时间')
      break;
    default:
      return title
      break;
  }
}

export function trans_rule(schema_def_name) {
  switch (schema_def_name) {
    case '聚众管理':
      return i18n.t('聚众管理')
      break;
    case '静止管理':
      return i18n.t('静止管理')
      break;
    case '滞留管理':
      return i18n.t('滞留管理')
      break;
    case '缺员管理':
      return i18n.t('缺员管理')
      break;
    default:
      return schema_def_name
      break;
  }
}

function pad2(number) {
  return (number < 10 ? '0' : '') + number
}

export function decodeTime(time) {
  let hr = pad2(time.getHours())
  let min = pad2(time.getMinutes())

  return `${hr} ${min} * * *`
}


 
export function getDocs() {
  return zoneData.data.map(item => { 
    return Object.assign(item, {
      geometry: {
        type: "Polygon",
        //  coordinates: [toLeft(item.zone_geometry.positions)]
        coordinates: [coordinate_calculatino({ module: "zoneDesign", position_info: item.zone_geometry.positions, position_direction_rdp: "toLeft", position_direction: "toLeftBottom" })]
      },
      properties: {
        // rectangle: true, //矩形区分
        uid: item.uid
      },
      slid: item.sublocation_uid,
      type: "Feature"
    })
  })

}


export var zoneData = observable({
  data: [],
  loadData: action(function (xs) {
    zoneData.data = xs
  }),
  editOne: flow(function* (row) {
    let updateIndex = zoneData.data.findIndex(item => {
      return item.uid === row.uid
    })
    if (updateIndex !== -1) {
      row = Object.assign(row, {
        geometry: {
          type: "Polygon",
          coordinates: [coordinate_calculatino({ module: "zoneDesign", position_info: row.zone_geometry.positions, position_direction_rdp: "toLeft", position_direction: "toLeftTop" })]
          // coordinates: [toLeft(row.zone_geometry.positions)]
        },
        properties: {
          // rectangle: true,
          uid: row.uid
        },
        slid: row.sublocation_uid,
        type: "Feature"
      })
      zoneData.data = [
        ...zoneData.data.slice(0, updateIndex),
        row,
        ...zoneData.data.slice(updateIndex + 1)
      ]
    }
  }),
  addOne: flow(function* (row) {
    row = Object.assign(row, {
      geometry: {
        type: "Polygon",
        coordinates: [coordinate_calculatino({ module: "zoneDesign", position_info: row.zone_geometry.positions, position_direction_rdp: "toLeft", position_direction: "toLeftTop" })]
        // coordinates: [toLeft(row.zone_geometry.positions)] 
      },
      properties: {  // rectangle: true,
        uid: row.uid
      },
      slid: row.sublocation_uid,
      type: "Feature"
    })
    zoneData.data = [row, ...zoneData.data]
  }),
  delOne: flow(function* (row) {
    if (row) {
      let updateIndex = zoneData.data.findIndex(item => {
        return item.uid === row
      })
      zoneData.data.splice(updateIndex, 1)
      zoneData.data = [...zoneData.data]
    }
  }),
})


export function Qj_position(position) {
  let Qj_position = position.map(item => {
    return `${item.x / zoneStore.image_scale / 100} ${item.y / zoneStore.image_scale / 100}`
  })
  return Qj_position.join(',')
}

export async function Qj_data() {
  let quanji_data = await APIEndQj.get("fence/list?mapId=1")
  zoneStore.setQjData(quanji_data.data)
}

export function zone_filter() {
  let data = []
  let zone_data = getDocs().filter(item => {
    return item.slid === zoneStore.select_floor_uid
  }) 
  zone_data.map(zone => {
    let zone_index = findIndex(zoneStore.zoneFilter, function (o) { return o.schema_def_id === zone.zone_category_id || o.schema_def_id === zone.schema_def_id });
    if (zone_index !== -1) data.push(zone)
  }) 
  return data

}
