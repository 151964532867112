import { findIndex, propEq, update, remove } from 'ramda'
import {polygonContains} from 'd3'
import i18n from './i18n' 

export function arrayToObj(array, key) {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item.role_name,
    };
  }, initialValue);
}

export function arrayToObjCategory(array, key, name) {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item[name],
    };
  }, initialValue);
}

export function arrayToObjPeople(array, key) {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item['trackable_name']?item['trackable_name']:item['name'],
    };
  }, initialValue);
}

function getTrackableName(objectList, id) {
  let res = objectList.find(item => item.uid === id)
  let result = res ? res.name : '' 
  return result
}
export function arrayToTaskObj(array, objectList, zoneList, key) {
  const initialValue = {};
  return array.reduce((obj, option) => {
    return {
      ...obj,
      [option[key]]: getTrackableName(zoneList, option.zone_uid)
    }; 
  }, initialValue);
}

export function taskObject(array,zoneList,key){//任务对象 
  const initialValue = {};
  return array.reduce((obj, option) => { 
    return {
      ...obj,
      [option.zone_uid]: getTrackableName(zoneList, option.zone_uid)
    };  
  }, initialValue);
}

export function replaceItem(array, item, key) {
  let pos = findIndex(propEq(key, item[key]))(array)
  return update(pos, item, array)
}

export function removeItem(array, item, key) {
  let pos = findIndex(propEq(key, item[key]))(array)
  return remove(pos, 1, array)
}

export const localization = {
  header: {
    actions: "操作"
  },
  body: {
    emptyDataSourceMessage: '没有记录',
    filterRow: {
      filterTooltip: '过滤',
    }
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} 共 {count}',
    labelRowsSelect: '行',
    labelRowsPerPage: '每页行数',
    firstAriaLabel: '首页',
    firstTooltip: '首页',
    previousAriaLabel: '上一页',
    previousTooltip: '上一页',
    nextAriaLabel: '下一页',
    nextTooltip: '下一页',
    lastAriaLabel: '末页',
    lastTooltip: '末页',
  },
  grouping: {
    placeholder: '拖放表头到此处分组',
    groupedBy: '分组依据:'
  },
  toolbar: {
    exportTitle: '导出',
    exportAriaLabel: '导出',
    exportCSVName: '导出CSV',
    exportPDFName: '导出PDF',
    searchTooltip: '搜索',
    searchPlaceholder: "搜索",
    searchAriaLabel: "搜索",
    clearSearchAriaLabel: "清除搜索"
  }
}

export function imageOverlayProps(floorplan) {
  const dim_x = floorplan.width // * floorplan.image_scale;
  const dim_y = floorplan.height // * floorplan.image_scale;

  const image_offset_x = floorplan.image_offset_x // * floorplan.image_scale;
  const image_offset_y = floorplan.image_offset_y // * floorplan.image_scale;

  const image_center_position = {
    lng: lengthToDegree(image_offset_x /* /100 */),
    lat: lengthToDegree(image_offset_y /* /100 */),
  };

  // lng: x
  // lat: y
  const SW = {
    //lng: (image_center_position.lng + lengthToDegree(dim_x / 2)),
    lng: (image_center_position.lng + lengthToDegree(dim_x / 2)),
    //lat: (image_center_position.lat - lengthToDegree(dim_y / 2)),
    lat: (image_center_position.lat - lengthToDegree(dim_y / 2)),
  };
  const NE = {
    //lng: (image_center_position.lng - lengthToDegree(dim_x / 2)),
    lng: (image_center_position.lng - lengthToDegree(dim_x / 2)),
    //lat: (image_center_position.lat + lengthToDegree(dim_y / 2)),
    lat: (image_center_position.lat + lengthToDegree(dim_y / 2)),
  };
  const bounds = [[SW.lat, SW.lng], [NE.lat, NE.lng]];
  return {
    bounds,
    position: {
      image_center_position,
    },
    rotation: floorplan.image_rotation,
  };
}

export function lengthToDegree(l) {
  return (360 * l) / 40075161.2;
}

export function degreeToLength(d) {
  return d * (40075161.2 / 360)
}

export function getTrackableViolate(realtimeStore, id) {
  let violates = realtimeStore.violates.filter(item => item.violate === true)
  let res = violates.find(item => item.dev_id === id)
  return res ? { violate: res.violate, rule_name: res.rule_name } : { violate: false, rule_name: '' }
}
export function getZoneViolate(realtimeStore, id) {
  let res = realtimeStore.violates.filter(item => item.rule_zone_uid === id)
  res = res.sort((a, b) => formDate(b.ts) > formDate(a.ts) ? 1 : -1)[0]

  if (res && res.violate) {
    return { violate: true, rule_name: res.rule_name }
  } else {
    return { violate: false, rule_name: null }
  }
}



function formDate(time) {
  const thisTime = time.replace(/ /g, '/')
  var nowTime = new Date(thisTime)
  nowTime = nowTime.getTime()
  return nowTime
}

export function getTrackvideo(realtimeStore, zone_data, uid) {
  let res = realtimeStore.violates.filter(item => item.dev_id === uid)
  res = res.sort((a, b) => formDate(b.ts) > formDate(a.ts) ? 1 : -1)[0]

  if (res) {
    let zone = zone_data.find(item => item.uid === res.rule_zone_uid)

    return { camera_url: zone.camera_url, name: zone.name }
  } else {
    return { camera_url: null, name: i18n.t('暂无') }
  }

}


export function filterPoint(mapFilterStore, zonedata, pointUid) {

  let obj = mapFilterStore.data.find(item => {
    return item.dev_id === pointUid
  })
  let cameraList = zonedata.filter(item => {
    if (item.camera_url) {
      return item
    }
  })
  let info = cameraList.filter(zone => {
    var data = []
    zone['zone_geometry']['positions'].map(item => {
      data.push([item.x, item.y])
    })
    if (polygonContains(data, [obj.x, obj.y])) {
      return zone
    }
  })
  // console.log(toJS(info))
  return info
}

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}
 
export function reverseObj (obj) {
  return Object.keys(obj).reverse().reduce((a,key,i)=>{
      a[key] = obj[key];
      return a;
  }, {})
};
// 遍历获得某节点下所有nodes, 不包括本节点
export function collectSubNodes(node, childrenFieldName) {
  let nodes = []
  function recur(node) {
    if (node.hasOwnProperty(childrenFieldName)) {
      return node[childrenFieldName].map(item => {
        nodes.push(item)
        recur(item)
      })
    } else {
      return
    }
  }
  let scalarNode = Array.isArray(node) ? node[0] : node
  recur(scalarNode)
  return nodes
}