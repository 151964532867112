import React, { useEffect, useRef } from 'react'
import { observable, action, } from 'mobx'
import { mapFilterStore } from './../mapFilterStore'
import {SpriteMaterial, Sprite, Group} from 'three'
import { TextureLoader } from 'three'
import ReactDOM from 'react-dom'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx';
import { APIFile, APIEndExt } from './../../../api'
const circleTexture = new TextureLoader().load('/imgs/circle.png')
const z = 0 // 人放置高度 
const spriteZ = z + 1  // 注释符号放置高度
const spriteScale = 0.036 // 注释符号缩放量



export const threeMapStore = observable({
  alignment: '',
  setAlignment: action(val => {
    threeMapStore.alignment = val
  }),
  selectFloorUid: '',
  setSelectFloorUid: action(val => {
    threeMapStore.selectFloorUid = val
  }),
  selectFloor: null,
  setSelectFloor: action(val => {
    threeMapStore.selectFloor = val
  }),
  viewer: null,
  setViewer: action(val => {
    threeMapStore.viewer = val
  }),
  tkoModel: null,
  setTkoModel: action(val => {
    threeMapStore.tkoModel = val
  }),
  // gltf_url:null,
  // setGltfUrl:action(val=>{
  //   threeMapStore.gltf_url=val
  // })
  // 当前选中的gltf
  sublocation_gltf: null,
  setSublocationGltf: action(val => {
    threeMapStore.sublocation_gltf = val
  }),
})

export function getXY(x, y, floor_info) {
  let { image_offset_x, image_offset_y, image_scale } = floor_info
  return [
    (x) / 1000 - image_offset_x * image_scale / 10,
    - (y / 1000) + image_offset_y * image_scale / 10
  ]
}





export const tkoStore = observable({
  tkos: {},
  selected_devid: null,
  addTko: action((item, threeModel, sprite) => {
    tkoStore.tkos[item.dev_id] =
    {
      x: item.x,
      y: item.y,
      name: item.name,
      dev_id: item.dev_id,
      obj: threeModel,
      sprite: sprite
    }
  }),
  selectTko: action(id => {
    tkoStore.selected_devid = id
  })
})

export const tkos_init_data = () => {
  // console.log('初始tkos')
  mapFilterStore.data.map(recordContent => {
    const [x, y] = getXY(recordContent.x, recordContent.y, threeMapStore.selectFloor)
    // tko头上圆渲染
    const annotationSpriteMaterial = new SpriteMaterial({
      map: circleTexture,
      depthTest: false,
      depthWrite: false,
      sizeAttenuation: false
    });
    const annotationSprite = new Sprite(annotationSpriteMaterial);
    annotationSprite.scale.set(spriteScale, spriteScale, spriteScale)
    annotationSprite.position.copy({
      "x": x,
      "y": spriteZ,
      "z": y
    })
    annotationSprite.userData.id = recordContent.dev_id
    let tkoModelCopy = threeMapStore.tkoModel.clone()
    tkoStore.addTko({
      x: x,
      y: y,
      name: recordContent.name,
      dev_id: recordContent.dev_id,
    },
      tkoModelCopy,
      annotationSprite
    )

  })
}
export const filter_data = async () => {
  var allChildren = threeMapStore.viewer.scene.children;

  for (var i = allChildren.length - 1; i >= 1; i--) {
    if (allChildren[i] instanceof Group) {
      if (allChildren[i]['name'] !== '') {
        threeMapStore.viewer.scene.remove(allChildren[i]);
      }

    }
  }

  mapFilterStore.data.map(recordContent => {

    if (recordContent.slid === threeMapStore.selectFloorUid) {
      // console.log('渲染')
      const [x, y] = getXY(recordContent.x, recordContent.y, threeMapStore.selectFloor)
      let tkoModelCopy = tkoStore.tkos[recordContent.dev_id].obj
      // 对象注释组件
      var annotationDiv = document.createElement('div')
      ReactDOM.render(<AnnotationDiv
        id={recordContent.dev_id}
        name={recordContent.name}
      // battery={tko_data[recordContent.dev_id].battery}
      />, annotationDiv
      )
      const annotationLabel = new CSS2DObject(annotationDiv)
      annotationLabel.position.copy({
        "x": x,
        "y": spriteZ,
        "z": y
      })
      threeMapStore.viewer.scene.add(tkoModelCopy)
      // viewer.scene.add(annotationSprite)
      // viewer.scene.add(annotationLabel) 
      tkoModelCopy.position.set(x, z, y)
      // tkoStore.tkos[recordContent.dev_id].obj.position.set(x, z, y)
    }
    // else {
    //   console.log('移除')
    //   let obj_info = tkoStore.tkos[recordContent.dev_id]
    //   if (obj_info) {
    //     threeMapStore.viewer.scene.remove(obj_info['obj'])
    //   }

    // }
  })
}

const AnnotationDiv = observer((props) => {
  return (
    <div className="annotationLabel">
      {/* {props.name} */}
      {/* <div className="annotationDescription" key={props.id}
        style={{ display: props.id === tkoStore.selected_devid ? "block" : "none" }}>
        {props.battery}
      </div> */}
    </div>
  )
})

export async function downLoadGltf(floor_index) {
  let floor_info = mapFilterStore.allFloorplans[floor_index]
  let sublocations_data_EndExt = await APIEndExt.get('/sublocations')
  let floorId = sublocations_data_EndExt.data.findIndex(every => every.uid === floor_info.sublocation_uid)
 
  if (floor_info['threed_map_file_name'] !== sublocations_data_EndExt.data[floorId]['threed_map_file_name']) {
    floor_info = Object.assign(floor_info, {
      threed_map_file_name: sublocations_data_EndExt.data[floorId]['threed_map_file_name'],
      isUpdate_threed: false
    })
    mapFilterStore.setFloorInfo(floor_index, 'isUpdate_threed', false)
    mapFilterStore.setFloorInfo(floor_index, 'threed_map_file_name', sublocations_data_EndExt.data[floorId]['threed_map_file_name'])
  }
  
  if (!floor_info.isUpdate_threed && floor_info.threed_map_file_name != null) {
    mapFilterStore.setFloorInfo(floor_index, 'isUpdate_threed', true)
    await APIFile.get(`/map_files?file_name=${floor_info.threed_map_file_name}`, { responseType: 'blob' }
    ).then(res => {
      let blob = new Blob([res.data], { type: "application/octet-stream" });
      let url = window.URL.createObjectURL(blob);
      let floorId = mapFilterStore.allFloorplans.findIndex(every => every.uid === floor_info.uid)
      // console.log('下载图片')
      mapFilterStore.setFloorInfo(floorId, 'threed_map_svg', url)
      threeMapStore.setSublocationGltf(mapFilterStore.allFloorplans[floorId])

    })
  } else {
    threeMapStore.setSublocationGltf(floor_info)
  }
}
