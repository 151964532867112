import CssBaseline from "@material-ui/core/CssBaseline"
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import App from './components/App'
import Login from './components/Login2'
import RouteAuthenticated from './AuthenticatedRoute'

// const theme = createMuiTheme({
//   palette: {
//     type: "dark",
//     primary: deepOrange,
//     secondary: blueGrey,
//     background: { default: "#080808", paper: "#121212" }
//   }
// });
// natural way to deal different layout page.
function Main ()  {
  return (
    <React.Fragment>
      <CssBaseline />
      <Switch>
        <Route path="/" exact component={Login}/>
        <RouteAuthenticated path="/:apparea" component={App}/>
      </Switch>
    </React.Fragment>
  )
}

export default Main