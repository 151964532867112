import axios from 'axios'
import { appStore } from './appStore'
import jwt_decode from 'jwt-decode'
import { env } from './env'; 
// const abortCtrl = new AbortController();

const ExtAPISvr = env.REACT_APP_ExtAPI ? env.REACT_APP_ExtAPI : process.env.REACT_APP_ExtAPI
const RdpAPISvr = env.REACT_APP_RdpAPI ? env.REACT_APP_RdpAPI : process.env.REACT_APP_RdpAPI
const RdpWSSvr = env.REACT_APP_Rdp_WS ? env.REACT_APP_Rdp_WS : process.env.REACT_APP_Rdp_WS
const RdpAPIAuth_Email = env.REACT_APP_RdpAPIAuth_Email ? env.REACT_APP_RdpAPIAuth_Email : env.REACT_APP_RdpAPIAuth_Email
const RdpAPIAuth_Pass = env.REACT_APP_RdpAPIAuth_Pass ? env.REACT_APP_RdpAPIAuth_Pass : process.env.REACT_APP_RdpAPIAuth_Pass
const Project = env.REACT_APP_Proj ? env.REACT_APP_Proj : process.env.REACT_APP_Proj
const VideoSvr = env.REACT_APP_VideoSvr ? env.REACT_APP_VideoSvr : process.env.REACT_APP_VideoSvr
const QjAPISvr = env.REACT_APP_QjAPI ? env.REACT_APP_QjAPI : process.env.REACT_APP_QjAPI//全迹
const project_dis = env.REACT_APP_Project ? env.REACT_APP_Project : process.env.REACT_APP_Project

export const APP_Project = Project
export const APIEndItg = axios.create({
  baseURL: `https://${ExtAPISvr}/itg`,
  timeout: 10000
})

export const APIEndRdp = axios.create({
  baseURL: RdpAPISvr,
  timeout: 10000
})

export const APIEndExt = axios.create({
  baseURL: `https://${ExtAPISvr}/api`,
  timeout: 10000
})

export const APIFile = axios.create({
  //baseURL: `http://101.200.179.166:5566`,
  baseURL: `https://${ExtAPISvr}/file`,
  timeout: 10000 
})
export const APIToken = axios.create({
  baseURL: VideoSvr,
  timeout: 10000 
})

export const APIEnd = axios.create({
  baseURL: `https://${ExtAPISvr}`,
  timeout: 10000
})

// 全迹
export const APIEndQj = axios.create({
  baseURL: QjAPISvr,
  timeout: 10000
})



// 请求拦截: log
/*
APIEndRdp.interceptors.request.use(request => {
  console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
})
APIEndExt.interceptors.request.use(request => {
  console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
})*/
// 响应拦截
project_dis === "rdp" && APIEndRdp.interceptors.response.use(
  resp => {
    return resp
  },
  error => {
    console.log(error)
    const originalRequest = error.config
    if (error.response) {
      if (error.response.data.status === 'error' && error.response.data.message === 'Token expired' && !originalRequest._retry) {
        originalRequest._retry = true

        return APIEndRdp.post('/auth/refresh_token', {
          email: appStore.userEmail,
          token: appStore.userToken
        }).then(res => {
          let token = res.data.data.token
          APIEndRdp.defaults.headers['X-User-Token'] = token
          originalRequest.headers['X-User-Token'] = token
          appStore.setUserToken(token)
          return axios.request(originalRequest)
        })
      }
    }
    return Promise.reject(error)
  }
)

const pos_server = RdpWSSvr
export var pos_cnn;
// we need to get all tokens before any ajaxCall
// better syntax with async/await
export async function prepareAuthTokens(email, pass) {
  if (APIEndExt.defaults.headers.common['Authorization'] === undefined) {
    try {
      let res = await APIEndExt.post('/rpc/login', {
        //"email": "apiuser@api.com",
        //"password": "apiuser8848"
        "email": email,
        "password": pass
      })
      APIEndExt.defaults.headers.common['Authorization'] = `Bearer ${res.data[0].token}`
      res = await APIEndExt
        .get(`/business_roles_nosystem?id=eq.${res.data[0].me.business_role}`)

      if (res.data[0].role_type !== 'admin') {
        appStore.setUserMenus(res.data[0].allowed_actions)
      } else {
        appStore.setUserMenus(['ALL'])
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  if (project_dis === "rdp" && APIEndRdp.defaults.headers['X-User-Token'] === undefined) {
    try {
      // console.log(RdpAPIAuth_Email, RdpAPIAuth_Pass)
      let res = await APIEndRdp.post('/auth/log_in', {
        "email": RdpAPIAuth_Email,
        "password": RdpAPIAuth_Pass
      })
      // console.log("Rdp API Connected")
      let email = RdpAPIAuth_Email
      let token = res.data.data.token

      appStore.setUserToken(token)
      appStore.setUserEmail(email)
      let decoded = jwt_decode(token)
      appStore.setTokenExpireTime(decoded.iat + decoded.expiresIn)

      APIEndRdp.defaults.headers['X-User-Email'] = `${email}`
      APIEndRdp.defaults.headers['X-User-Token'] = `${token}`
      APIEndRdp.defaults.headers["X-User-Project"] = Project
      pos_cnn = new WebSocket(pos_server)


      appStore.setFallingWarn()
      pos_cnn.onopen = function () {
        pos_cnn.send(`
        {"jsonrpc": "2.0",
          "method": "add",
          "params": {
            "data": ["pos", "alarm", "notify", "user_msg"],
            "entity": "subscriptions",
            "userEmail": "${RdpAPIAuth_Email}",
            "authToken": "${APIEndRdp.defaults.headers['X-User-Token']}",
            "project_uid": "${Project}"
          },
        "id": 1
        }
        `)
      }

      pos_cnn.onerror = function (error) {
        console.log(`rdp websocket error: ${error.message}`)
        pos_cnn.close()
      }
      // pos_cnn.onmessage = async (ev) => {
      //   let ev_data = JSON.parse(ev.data)
      //   if (ev_data.params !== undefined) {
      //     if (ev_data.params.subscription === "alarm") {
      //       let obj = ev_data.params.data
      //       // 按钮消息
      //       if (obj.alarm_type_id === 6) {
      //         console.log('按钮消息')
      //         console.log(obj)
      //         // getAudio()
      //         // msgStore.setMsgtab(1, "violate", true)
      //         // appStore.setFallingWarn(true)
      //         // msgStore.addRecvMsg({
      //         //   obj_uid: obj.obj_uid,
      //         //   msgText: obj.additional_info.text,
      //         //   created_at: obj.created_at,
      //         //   type: 'rdp_emergency'
      //         // })
      //       }
      //     }

      //   }
      // }
      //消息中心
      // realtimeStore.fallings.on('entry-added', async (entry) => {
      //   console.log('消息中心---set---跌落')
      //   // let record = dsc.record.getRecord(entry)
      //   // await record.whenReady()
      //   // let data = record.get()
      //   // console.log(data)
      //   // getAudio()
      //   // msgStore.setMsgtab(0, "violate", true)
      //   // appStore.setFallingWarn(true)
      //   // APIEndExt.get('/messages?order=msg_id.desc').then(res => {
      //   //   msgStore.setFallingDownMsg(res.data)
      //   // })
      // })

    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  }

  // if (APIEndItg.defaults.headers.common['Authorization'] === undefined) {
  //   try {
  //     let res = await APIEndItg.post('/auth', {
  //       "username": "mid_api_server_client",
  //       "password": "P=z2,sS~<.C4bMhQ"
  //     })
  //     APIEndItg.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
  //   } catch (error) {
  //     throw new Error(error)
  //   }
  // }

  if (APIFile.defaults.headers.common['Authorization'] === undefined) {
    try {
      let res = await APIFile.post('/auth', {
        "username": "upload_server_client",
        "password": "P=z2,sS~<.C4bMhQ"
      })
      APIFile.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
    } catch (error) {
      throw new Error(error)
    }
  }

  // 全迹token
  if (project_dis === "qg" && APIEndQj.defaults.headers.common['Authorization'] === undefined) {
    try {
      let res = await APIEndQj.post('user/login', {
        "loginCode": env.REACT_APP_QjAPIAuth_Email ? env.REACT_APP_QjAPIAuth_Email : process.env.REACT_APP_QjAPIAuth_Email,
        "loginPwd": env.REACT_APP_QjAPIAuth_Pass ? env.REACT_APP_QjAPIAuth_Pass : process.env.REACT_APP_QjAPIAuth_Pass,
      })
      APIEndQj.defaults.headers.common['api_token'] = `${res.data.entity.api_token}`
    } catch (error) {
      throw new Error(error)
    }
  }

}

export function reconnection() {
  pos_cnn = new WebSocket(pos_server)
  pos_cnn.onopen = function () {
    pos_cnn.send(`
    {"jsonrpc": "2.0",
      "method": "add",
      "params": {
        "data": ["pos", "alarm", "notify"],
        "entity": "subscriptions",
        "userEmail": "${RdpAPIAuth_Email}",
        "authToken": "${APIEndRdp.defaults.headers['X-User-Token']}",
        "project_uid": "${Project}"
      },
    "id": 1
    }
    `)
  }
}
