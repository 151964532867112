import React from 'react'
import i18n from '../i18n'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import TrackChangesOutlined from '@material-ui/icons/TrackChangesOutlined'
import LabelOutlined from '@material-ui/icons/LabelOutlined'
import PeopleAltOutlined from '@material-ui/icons/PeopleAltOutlined'
import CategoryOutlined from '@material-ui/icons/CategoryOutlined'
import MyLocationOutlined from '@material-ui/icons/MyLocationOutlined'
import GavelOutlined from '@material-ui/icons/GavelOutlined'
import AspectRatioOutlined from '@material-ui/icons/AspectRatioOutlined';
// import AssignmentLateOutlined from '@material-ui/icons/AssignmentLateOutlined';
import AssignmentIndOutlined from '@material-ui/icons/AssignmentIndOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import EditLocationOutlinedIcon from '@material-ui/icons/EditLocationOutlined';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import MultilineChartOutlinedIcon from '@material-ui/icons/MultilineChartOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputIcon from '@material-ui/icons/Input';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DirectionsIcon from '@material-ui/icons/Directions';
import NavigationIcon from '@material-ui/icons/Navigation';
import PagesIcon from '@material-ui/icons/Pages';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import NoSimIcon from '@material-ui/icons/NoSim';
import { DuoOutlined } from '@material-ui/icons';

const allMenuActions = {
  dash_board: { label: i18n.t("看板"), icon: <DashboardIcon /> },
  data_board: { label: i18n.t("数据看板"), icon: <DashboardIcon /> },
  //home: {label: i18n.t("项目仪表板"), icon: <DashboardIcon />},
  message_center: { label: i18n.t("消息中心"), icon: <InfoOutlinedIcon /> },
  realtime_map: { label: i18n.t("实时地图"), icon: <MapOutlinedIcon /> },
  three_map: { label: i18n.t("3D地图"), icon: <ThreeSixtyIcon /> },
  // multi: { label: i18n.t("九宫格"), icon: <PagesIcon /> },
  multi_map: { label: i18n.t("九宫格地图"), icon: <PagesIcon /> },
  track_playback: { label: i18n.t("轨迹回放"), icon: <TrackChangesOutlined /> },
  labels: { label: i18n.t("标签"), icon: <LabelOutlined /> },
  caption: { label: i18n.t("解说词"), icon: <LabelOutlined /> },
  tracobgroup: { label: i18n.t("定位对象组"), icon: <PeopleAltOutlined /> },
  trackcategory: { label: i18n.t("定位对象类别"), icon: <CategoryOutlined /> },
  tracobj: { label: i18n.t("定位对象"), icon: <MyLocationOutlined /> },
  trackbatch: { label: i18n.t("批次维护"), icon: <MyLocationOutlined /> },
  importdata: { label: i18n.t("导入数据"), icon: <InputIcon /> },
  fencecategory: { label: i18n.t("管理规则"), icon: <GavelOutlined /> },
  zonecategory: { label: i18n.t("电子围栏类别"), icon: <GavelOutlined /> },
  elecfence: { label: i18n.t("电子围栏定义"), icon: <AspectRatioOutlined /> },
  // zone_violates: {label: i18n.t("规则违反情况"), icon: <AssignmentLateOutlined />},
  task_category: { label: i18n.t("任务类别"), icon: <GroupWorkOutlinedIcon /> },
  task_preform_define: { label: i18n.t("任务准备清单定义"), icon: <AssignmentOutlinedIcon /> },
  task_content: { label: i18n.t("任务内容表单定义"), icon: <ListAltOutlinedIcon /> },
  task_schedule: { label: i18n.t("调度计划"), icon: <ScheduleOutlinedIcon /> },
  task_object: { label: i18n.t("任务对象"), icon: <EmojiObjectsOutlinedIcon /> },
  task: { label: i18n.t("下达任务"), icon: <AssignmentIndOutlined /> },
  task_history: { label: i18n.t("任务执行报告"), icon: <AssessmentOutlinedIcon /> },
  task_map: { label: i18n.t("任务地图"), icon: <EditLocationOutlinedIcon /> },
  travelreport: { label: i18n.t("行程分析"), icon: <TimelineOutlinedIcon /> },
  alarmreport: { label: i18n.t("报警分析"), icon: <MultilineChartOutlinedIcon /> },
  area_inout_report: { label: i18n.t("区域进出"), icon: <DeveloperModeIcon /> },
  visitor: { label: i18n.t("配/还卡"), icon: <CreditCardIcon /> },
  visitor_blacklist: { label: i18n.t("黑名单管理"), icon: <NoSimIcon /> },
  elecfence_inout_report: { label: i18n.t("电子围栏进出"), icon: <DeveloperBoardIcon /> },
  // alarm_area: {label: i18n.t("警报区域"), icon: <DashboardIcon />},  
  pws_configure: { label: i18n.t("PWS配置"), icon: <PermDataSettingIcon /> },
  navigation: { label: i18n.t("导航"), icon: <NavigationIcon /> },
  navgation_routes: { label: i18n.t("导航路线管理"), icon: <DirectionsIcon /> },
  roll_call: { label: i18n.t('考勤点名'), icon: <AssignmentIndIcon /> },//考勤点名
  userandrole: { label: i18n.t("用户/角色"), icon: <AccountBoxOutlinedIcon /> },
  org: { label: i18n.t("组织结构"), icon: <AccountTreeOutlinedIcon /> },
  area: { label: i18n.t("区域"), icon: <CloudUploadIcon /> },
  map_import: { label: i18n.t("区域管理"), icon: <CloudUploadIcon /> },
  area_group: { label: i18n.t("区域组"), icon: <PagesIcon /> },
  syncdata: { label: i18n.t("同步定位系统"), icon: <SyncAltOutlinedIcon /> },
  video_follow: { label: i18n.t("视频随工"), icon: <DuoOutlined/> }
}

export const menuStructure = {
  "dash_board": null,
  "data_board": null,
  //"home": null,
  "message_center": null,
  [i18n.t("实时地图")]: {
    "realtime_map": null,
    "three_map": null,
  },
  "multi_map": null, //九宫格地图
  "video_follow": null, // 视频随工
  "track_playback": null,
  "labels": null,
  "caption":null,
  [i18n.t("定位对象管理")]: {
    "tracobgroup": null,
    "trackcategory": null,
    "tracobj": null,
    "trackbatch": null,
    "importdata": null
  },
  [i18n.t("电子围栏管理")]: {
    "fencecategory": null,
    "zonecategory": null,
    "elecfence": null,
    // "zone_violates": null
  },
  [i18n.t("任务管理(设置)")]: {
    "task_category": null,
    "task_preform_define": null,
    "task_content": null,
    "task_schedule": null,
    "task_object": null
  },
  [i18n.t("任务管理(下达)")]: {
    "task": null,
    "task_history": null,
    "task_map": null
  },
  [i18n.t("分析报表")]: {
    "travelreport": null,
    "alarmreport": null,
    "area_inout_report": null,
    "elecfence_inout_report": null
  },
  [i18n.t("访客管理")]: {
    "visitor": null,
    "visitor_blacklist": null,
  },
  [i18n.t("导航")]: {
    "navgation_routes": null,
    "navigation": null
  },
  "pws_configure": null,//pws配置
  // "navigation": null,
  "roll_call": null,
  // [i18n.t("看板")]: {  
  //   "alarm_area": null, 
  // },
  "userandrole": null,
  "org": null,
  // "map_import": null,
  // 区域管理
  [i18n.t("区域")]: {
    "map_import": null,
    "area_group": null,
  },
  "syncdata": null
}

export default allMenuActions
