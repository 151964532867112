import { observable, action,  } from 'mobx'
import { APIEndExt, pos_cnn } from '../../api'
 
export const taskStore = observable({
  current_data: [],
  setCurrent_data: action(xs => {
    taskStore.current_data = xs
  }),
  zone_List: [],
  setZone_List: action((val) => {
    taskStore.zone_List.push(val);
  }),
  executorData: [],
  setExecutorData: action(val => {
    taskStore.executorData = val
  }),
  executorList: [],
  setExecutorList: action((val) => {
    taskStore.executorList = val;
  }),
  task_history: [],
  setTask_history: action(val => { taskStore.task_history = val }),
  updatableChart: null,
  setUpdatableChart: action(val => {
    taskStore.updatableChart = val
  }),
  selectedFloorplan: [],
  setFloorplan: action(xs => { taskStore.selectedFloorplan = xs }),
  floor_sublocation_uid: null,//选中楼层  
  setFloorSubUid: action((val) => { taskStore.floor_sublocation_uid = val }),
  zone_data: [],
  setZoneData: action((val) => { taskStore.zone_data = val; }),
  taskSublocationSvg:null,
  setTaskSublocationSvg:action(val=>{
    taskStore.taskSublocationSvg=val
  })
})



async function posMessage() { 
  let res_history = await APIEndExt.get('/task_history_view')
  taskStore.setTask_history(res_history.data)
  //  执行人
  let res_trackable = await APIEndExt.get(`/task_history_map`)
  taskStore.setExecutorList(res_trackable.data)
  let resZone = await APIEndExt.get('/zone_baseinfos_view')
  taskStore.setZone_List(resZone.data)
  //  执行人所以列表
  let res_executor = await APIEndExt.get('/trackable_baseinfos_view')
  taskStore.setExecutorData(res_executor.data) 

  // pos_cnn.onmessage = async (ev) => { 
  //   let ev_data = JSON.parse(ev.data)
  //   if (ev_data.params !== undefined) { 
  //     // 如果是心跳, 则只是给定位置, 心跳操作不可以变动位置数据
  //     if ((ev_data.method === 'notify' && ev_data.params.data.object === 'TO' && ev_data.params.data.action === 'HB') || (ev_data.params.subscription === 'pos')) {
  //       let obj = ev_data.params.data
  //       if (obj.sl_id === taskStore.floor_sublocation_uid || (obj.sublocation_uid === taskStore.floor_sublocation_uid && obj.object === 'TO')) {
  //         //  执行人
  //         let executor = taskStore.executorList.filter(item => { 
  //           return item.executor_id === obj.dev_id
  //         })[0]
  //         var currentIndex = taskStore.current_data.length
        
  //         if (executor) {
  //           let res = taskStore.executorData.find(item => item.uid === obj.dev_id)
  //           let taskHistory = taskStore.task_history.find(item => item.id === executor.taskexec_id) 
  //           obj = Object.assign(obj, { name: res.form_data['姓名'] }, taskHistory)
  //           let testRes = taskStore.current_data.findIndex(item => {
  //             return item.identId ? item.identId === executor.taskexec_id : null
  //           })
  //           if (testRes === -1) {
  //             taskStore.current_data[currentIndex] = { identId: executor.taskexec_id, executor_data: obj, zone_data: '', task_obj_data: '' }
  //           } else {
  //             taskStore.current_data[testRes]['executor_data'] = obj
  //           }

  //           taskStore.updatableChart.executor_data(taskStore.current_data) 
  //           if (executor.target_zone) {
  //             let zone = taskStore.zone_List.find(every => {
  //               return every.uid === executor.target_zone
  //             })
  //             let testRes = taskStore.current_data.findIndex(item => {
  //               return item.identId === executor.taskexec_id
  //             })
  //             taskStore.current_data[testRes]['zone_data'] = zone
  //             taskStore.updatableChart.zone_task_data(taskStore.current_data)

  //           }
  //         }

  //         // 任务对象-定位对象
  //         let trackable_obj = taskStore.executorList.filter(item => {
  //           return item.target_trackable === obj.dev_id
  //         })[0]  
  //         if (trackable_obj) {
  //           let res = taskStore.executorData.find(item => item.uid === obj.dev_id)
  //           obj = Object.assign(obj, { obj_name: res.name })
  //           let testRes = taskStore.current_data.findIndex(item => {
  //             return item.identId ? item.identId === trackable_obj.taskexec_id : null;
  //           })
  //           if (testRes === -1) {
  //             taskStore.current_data[currentIndex] = { identId: trackable_obj.taskexec_id, task_obj_data: obj, zone_data: '', executor_data: '' }
  //           } else {
  //             taskStore.current_data[testRes]['task_obj_data'] = obj
  //           }
  //           taskStore.updatableChart.task_data(taskStore.current_data)
  //         }

  //         taskStore.updatableChart.line_data(taskStore.current_data)

  //       }
  //     }

  //   }
  // }
}

export { posMessage }
