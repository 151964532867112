import { observable, action } from 'mobx'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { realtimeStore } from './../../appStore'
const useStyles = makeStyles((theme) => ({
  root: {
    color: 'black',
  },
  total: {
    fontSize: 20,
    margin: 10
  },
  table: {
    height: 174,
    overflow: 'hidden'
  },
  tableCell: {
    height: 38,
    wordBreak: 'break-all',
    padding: 0,
  }
}));

export const dataBoardStore = observable({
  staffNum: {
    total: 0,
    tmall: 0,
    official: 0,
    employeeStore: 0
  },
  setStaffNum: action((val, info) => {
    dataBoardStore.staffNum[val] = info
  }),
  trackgroups: [],
  setTrackgrousp: action(val => {
    dataBoardStore.trackgroups = val
  }),
  sublocationSvg: null,
  setSublocationSvg: action(val => {
    dataBoardStore.sublocationSvg = val
  }),
  loadSvg: true,
  setLoadSvg: action(val => {
    dataBoardStore.loadSvg = val
  }),
  updatableChart: null,
  setUpdatableChart: action(val => {
    dataBoardStore.updatableChart = val
  }),
  groupsColor: {},
  setGroupsColor: action(val => {
    dataBoardStore.groupsColor = val
  }),
  groupsDep: {},
  setGroupsDep: action(val => {
    dataBoardStore.groupsDep = val
  })
})



// 总人数
function StaffNum() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <div className={classes.total}>
        TOTAL：
        <span style={{ color: 'green' }}>
          {realtimeStore.online}
        </span>
        &nbsp;{t("人")}
      </div>
      <Grid container spacing={3}>
        <Grid item xs>
          <p> {t('天猫商城')}</p>
          <p> {dataBoardStore.staffNum.tmall}</p>
        </Grid>
        <Grid item xs>
          <p> {t('官网')}</p>
          <p>   {realtimeStore.online}</p>
        </Grid>
        <Grid item xs>
          <p> {t('员工店')}</p>
          <p> {dataBoardStore.staffNum.employeeStore}</p>
        </Grid>
      </Grid>
    </>)
}

// 收发退换
function toTime(time) {
  let timer = new Date(time * 1000).toISOString().substr(11, 8).split(':')
  return `${timer[0]}小时${timer[1]}分${timer[2]}秒`
}

function Distance(props) {
  return (
    <>
      <Grid container spacing={3} >
        {
          props.trackgroups.map((item, index) => {
            return (
              <Grid item xs={6} key={index}
                style={{
                  borderRight: index === 0 || index === 2 ? '1px solid black' : '',
                  borderBottom: index === 0 || index === 1 ? '1px solid black' : '',
                }}>
                <LabeledComponent
                  name={item.group_name}
                  color={item.group_color}
                  time={toTime(item.total_duration)}
                  // time={moment(item.total_duration).format('HH:mm:ss')}
                  distance={item.total_distance}
                />
              </Grid>
            )
          })
        } 
      </Grid>
    </>
  )
}

// 考勤排行榜 
function Attendance(props) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} style={{ height: '100%' }}>
      <Table className={classes.table} size="small" aria-label="a dense table" >
        <TableBody>
          {props.attendances.map((row, index) => (
            <TableRow key={row.user_name}>
              <TableCell align="center" className={classes.tableCell} width='40'>{`No.${index + 1}`}</TableCell>
              <TableCell align="center" className={classes.tableCell} width='80'>{row.user_name}</TableCell>
              <TableCell align="center" className={classes.tableCell} width='100'>{row.parent_name}</TableCell>
              <TableCell align="center" className={classes.tableCell} width='150' style={{ paddingRight: 0 }}>{toTime(row.total_duration)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>)
}

const LabeledComponent = ({ name, color, time, distance }) =>
  <Grid container spacing={2} style={{ alignItems: 'center', height: 74 }} >
    <Grid item style={{ paddingLeft:0 }}>
      <Avatar variant="square" style={{ backgroundColor: color, color: 'white' }}>
        {name}
      </Avatar>
    </Grid>
    <Grid item xs={8} sm style={{ paddingLeft: 0,paddingRight:0 }}>
      <p className='p_margin0'>
        {distance ? `${distance}m`: '0m'}
      </p>
      <p className='p_margin0' style={{ whiteSpace: 'nowrap' }}>
        {time}
      </p>
    </Grid>
  </Grid>

export { StaffNum, Distance, Attendance }