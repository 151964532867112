import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {useStore} from './appStore'

const RouteAuthenticated = ({ component: Component, path}) => {
  const store = useStore() 
  if (store.isAuth===false || store.isAuth===null) {
    return <Redirect to="/" />;
  }

  return <Route component={Component} path={path} />;
};

export default RouteAuthenticated;