import React from 'react';
import { observable, action, toJS } from 'mobx'
import moment from 'moment'
import i18n from '../../i18n'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export const trackPlaybackStore = observable({
  updatableChartTrack: null,//轨迹
  setUpdatableChartTrack: action((val) => { trackPlaybackStore.updatableChartTrack = val; }),
 
  select_floor_info: null,//轨迹回放选中楼层信息
  setSelectFloorInfo: action(xs => {
    trackPlaybackStore.select_floor_info = xs
  }),

  isSelect: false,//从拖放结束开始绘制
  setIsSelect: action(val => {
    trackPlaybackStore.isSelect = val
  }),
 
  radioId: 1,
  setRadioId: action(val => {
    trackPlaybackStore.radioId = val
  }),
  gl: null,
  setGl: action(val => {
    trackPlaybackStore.gl = val
  }),

  track_sublocation_svg: null,
  setTrackSublocationSvg: action(val => { 
    trackPlaybackStore.track_sublocation_svg = val
  }),
  trackableList: [],
  setTrackableList: action(val => { 
    trackPlaybackStore.trackableList = val
   }),
  trackRefresh: false,
  setTrackRefresh: action(val => {
    trackPlaybackStore.trackRefresh = val
  }),
  // 轨迹总数据
  trackData: [],
  setTrackData: action(val => {
    trackPlaybackStore.trackData = val
  }),
  // 热图数据
  heatData: [],
  setHeatData: action(val => {
    trackPlaybackStore.heatData = val
  }), 
  filterTrackData: [],
  setFilterTrackData: action(val => {
    trackPlaybackStore.filterTrackData = val
  }),
})

var dates = {}
dates[i18n.t('今天')] = {
  // start: moment().utc(8).startOf('day').toISOString(),
  // end: moment().utc(8).endOf('day').toISOString()
  start:moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
  end:moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

} 
dates[i18n.t('昨天')] = {
  // start: moment().utc(8).subtract(1, 'days').startOf('day').toISOString(),
  // end: moment().utc(8).subtract(1, 'days').endOf('day').toISOString()
  start: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
  end: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss')
}
dates[i18n.t('本周至今')] = {
  // start: moment().utc(8).startOf('week').toISOString(),
  // end: moment().utc(8).endOf('day').toISOString()
  start: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
  end: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
}
dates[i18n.t('本月至今')] = {
  // start: moment().utc(8).startOf('months').toISOString(),
  // end: moment().utc(8).endOf('day').toISOString()
  start: moment().startOf('months').format('YYYY-MM-DD HH:mm:ss'),
  end: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
}
dates[i18n.t('自定义')] = {
  start: '',
  end: ''
}

export const compareDate = (time1, time2) => {
  // time1:选中时间 time2:比较时间 
  var d1 = new Date(time1);//选中时间
  var d2 = new Date(time2);//比较时间 
  return d1 > d2 ? false : true;//true过滤时间比较大的
}

export const filter_track_data = (data, time) => {
  let filter_data = data.map(every => {
    every['track_points'] = every['track_points'].filter(item => {
      return compareDate(time, item[2])
    })
    return every
  })
  return filter_data.filter(item => {
    return item.track_points.length > 0
  })
}
export function toLeft(info) {
  return info.map(item => {
    // let x_info = (item.x / zoneStore.image_scale / 100) + zoneStore.baseMapWidthHalf
    // let y_info = (item.y / zoneStore.image_scale / 100) + zoneStore.baseMapHeightHalf
    let x_info = (item[0] / 0.2 / 100) + 400
    let y_info = (item[1] / 0.2 / 100) + 268
    return [x_info, y_info]
  })
}
export { dates }

export const LabeledComponent = ({ label, component }) =>
  <Grid container spacing={1} xs={2} item>
    <Grid item xs={4}>
      <Typography id="range-slider" gutterBottom align="right">
        {label}
      </Typography>
    </Grid>
    <Grid item xs>
      {component}
    </Grid>
  </Grid>
