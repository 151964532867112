import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import React, { useState, useEffect } from 'react';
import { Box, Center, Cover, Stack } from 'react-every-layout';
import { observer } from 'mobx-react-lite'
import { ReactComponent as Logo } from '../../logo.svg';
import { useStore } from '../../appStore'
import { prepareAuthTokens } from './../../api.js';
import { useTranslation } from 'react-i18next';
import { applySnapshot } from 'mobx-state-tree'
import { APIEndExt } from '../../api'
import { enter_exit_data } from '../../appStore'
import Skeleton from '@material-ui/lab/Skeleton';
import { env } from '../../env';
import CircularProgress from '@material-ui/core/CircularProgress';

 
const useStyles = makeStyles(theme => createStyles({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  center: {
    width: '90vw',
    marginTop: '5vh'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main
  },
  stack: {
    minHeight: '30vh',
    justifyContent: "space-between"
  },
  login: {
    textAlign: 'center',
    letterSpacing: '0.3em'
  }
}))
const REACT_APP_Project=env.REACT_APP_Project?env.REACT_APP_Project:process.env.REACT_APP_Project
const REACT_APP_COMPANY=env.REACT_APP_COMPANY?env.REACT_APP_COMPANY:process.env.REACT_APP_COMPANY
function Login(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles()
  const [user, setUser] = useState("")
  const [pwd, setPwd] = useState("")
  const [loading, setLoading] = useState(true)
  const [inlogin, setInLogin] = useState(false)
  const [isTip, setIsTip] = useState(false)

  const store = useStore()
  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search)
    const loginid = urlParams.get('loginid')
    if (loginid) {
      prepareAuthTokens('comand@rdp.com', 'comand1234').then(
        () => {
          REACT_APP_Project === "qg" && store.setOpen(false);
          setLoading(false)
          store.setIsDisplay(false)
          store.setAuth(true)
          store.setUsername('iwork')
          localStorage.setItem("call", "show")
          props.history.replace('/realtime_map')
        },
        (error) => {
          console.log(error)
          store.setAuth(false)
        }
      )
    } else {
      setLoading(false)
      setIsTip(true)
    }

    // return ()=> { 
    //   controller.abort()
    // }

  }, [])

  async function handleLogin() {
    let now = new Date().toJSON()
    let last24HourTime = new Date(Date.now() - 86400 * 1000).toJSON()
    setInLogin(true)
    try { 
      await prepareAuthTokens(user, pwd)
      store.setAuth(true)
      store.setUsername(user)
      let res = await APIEndExt.get(`/enter_exit_sublocations?and=(enter_time.gte.${last24HourTime},enter_time.lte.${now})&order=id.desc`)
      res.data.length !== 0 && applySnapshot(enter_exit_data, { xs: res.data })
      setInLogin(false) 
      localStorage.setItem("call", "noShow")
      props.history.replace('/home') 
    } catch (error) {
      console.log(error)
      store.setAuth(false)
      setInLogin(false)
    }
  }

  function handleKeyDown(event) {
    if (event.key==='Enter') {
      handleLogin()
    }
  }

  return (
    <>
      {loading ? <Skeleton variant="rect" width={"100%"} height={500} /> :
        isTip ? <Cover>
          <Center className={classes.center}>
            <Stack className={classes.stack}>
              <Box>
                <Stack style={{ alignItems: 'center' }}>
                  <Typography variant="h5">
                    {REACT_APP_COMPANY}
                  </Typography>
                  <div>
                    <Avatar className={classes.avatar}>
                      <LockIcon />
                    </Avatar>
                  </div>
                  <div>
                    <Typography variant="h6"
                      className={classes.login}
                      color="textSecondary">
                      {t('登录')}
                    </Typography>
                  </div>
                  <TextField
                    value={user}
                    onKeyDown={handleKeyDown}
                    onChange={e => setUser(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="user"
                    label={t("用户名")}
                    name="user"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    value={pwd}
                    type="password"
                    onKeyDown={handleKeyDown}
                    onChange={e => setPwd(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="pwd"
                    label={t("密码")}
                    name="pwd"
                  />
                </Stack>
              </Box>
            </Stack>
            <Box style={{display: "flex", justifyContent: "center"}}>
              {
              inlogin? <CircularProgress/> :
              <Button
                fullWidth
                disabled={inlogin}
                variant="contained"
                size="large"
                color="primary"
                style={{ letterSpacing: '0.5em'}}
                onClick={handleLogin}
              >
                {t('登录')}
              </Button>}
            </Box>
            <Box>
              {
                (store.isAuth === false) &&
                <Typography style={{ textAlign: 'center' }} variant='subtitle1' color='error'>{t('登录失败, 请检查用户名密码')}</Typography>
              }
            </Box>
            <div style={{ margin: 'auto' }}>
              <Logo />
            </div>
          </Center>
        </Cover> : null}
    </>
  );
}

export default observer(Login);
