import { Slot } from 'nano-slots'
import Skeleton from '@material-ui/lab/Skeleton';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { observable, toJS, reaction } from 'mobx'
import React, { Suspense, lazy, useEffect } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { appStore } from '../appStore'
import { ReactComponent as CoverImage } from '../CoverImage.svg';
import allMenuActions, { menuStructure } from './appMenuDefines'
import { isEmpty } from '../utils'
import { useTranslation } from 'react-i18next';
import { realtimeStore } from './../appStore'
import { APIEndItg, APIEndExt } from './../api'
import { getAudioMap } from './RealtimeMap/mapFilterStore'
import { mapFilterStore } from './../components/RealtimeMap/mapFilterStore'
import { mapDataInit } from './../appStore'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { env } from './../env';
// code splitting
// 电子围栏 
const Elecfence = lazy(() => import('./ElecFence/Fence'))
const FenceCategory = lazy(() => import('./ElecFence/FenceCategory'))
const Zoneviolates = lazy(() => import('./ElecFence/Zoneviolates'))
const ZoneCategory = lazy(() => import('./ElecFence/ZoneCategory'))

const Labels = lazy(() => import('./Labels'))
const Org = lazy(() => import('./Org'))
const Project = lazy(() => import('./Project'))
// 任务管理 
const TaskCategory = lazy(() => import('./Task/TaskCategory'))
const TaskObject = lazy(() => import('./Task/TaskObject'))
const TaskSchedule = lazy(() => import('./Task/TaskSchedule'))
const TaskPreformDefine = lazy(() => import('./Task/TaskPreformDefine'))
const TaskContent = lazy(() => import('./Task/TaskContent'))
const Task = lazy(() => import('./Task/Task'))
const TaskMap = lazy(() => import('./Task/TaskMap'))
const TaskHistory = lazy(() => import('./Task/TaskHistory'))
// 定位对象管理
const TrackableObj = lazy(() => import('./TrackableObj'))
const TrackCategory = lazy(() => import('./TrackCategory'))
const TrackGroup = lazy(() => import('./TrackGroup'))
const TrackBatch = lazy(() => import('./TrackBatch'))
const ImportData = lazy(() => import('./ImportData'))

const UserAndRole = lazy(() => import('./UserAndRole'))

// 访客管理 
const Visitor = lazy(() => import('./Visitor'))
const VisitorBlacklist = lazy(() => import('./Visitor/VisitorBlacklist'))

// 安全双防管理 
const Safety = lazy(() => import('./Safety'))
const MsgCenter = lazy(() => import('./MsgCenter'))

// 实时地图
const RealtimeMap = lazy(() => import('./RealtimeMap/RealTimeMap'))
const ThreeMap = lazy(() => import('./RealtimeMap/ThreeMap'))
const MultiMap = lazy(() => import('./MultiMap'))//九宫格地图


const TrackPlayback = lazy(() => import('./TrackPlayback'))
// 报表分析 
const TravelReport = lazy(() => import('./ReportForm/Travel'))
const AlarmReport = lazy(() => import('./ReportForm/AlarmReport'))
const RdpDataInte = lazy(() => import('./RdpDataInte'))
// 看板 
const DashBoard = lazy(() => import('./DashBoard'))
const AlarmArea = lazy(() => import('./DashBoard/AlarmArea'))
// 数据看板
const DataBoard = lazy(() => import('./DataBoard'))
// Area 报告
const AreaReport = lazy(() => import('./InoutReport/AreaReport'))
// ElecFence 报告
const ElecfenceReport = lazy(() => import('./InoutReport/ElecfenceReport'))
// 区域管理
const MapImport = lazy(() => import('./MapImport'))
const AreaGroup = lazy(() => import('./MapImport/AreaGroup'))//区域组
// 考勤点名
const RollCall = lazy(() => import('./RollCall'))
// pws配置
const PWSConfigure = lazy(() => import('./PWS_configure'))
// 视频随工
const VideoFollow = lazy(() => import('./VideoFollow'))
// 导航
const Navigation = lazy(() => import('./Navigation'))
const NavigationRoutes = lazy(() => import('./NavigationRoutes'))
// 解说词
const Caption = lazy(() => import('./Caption'))


const drawerWidth = 260;
const menu = observable({
  nodes: {},
  addNode(nodeName) {
    menu.nodes[nodeName] = false
  },
  toggleNode(nodeName) {
    menu.nodes[nodeName] = !menu.nodes[nodeName]
  }
})

// 菜单隐藏
const Menu_Disabled = JSON.parse(env.REACT_APP_Menu_Disabled ? env.REACT_APP_Menu_Disabled : process.env.REACT_APP_Menu_Disabled)

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingLeft: 15,
    paddingRight: 15
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerParentLvl: {
    fontWeight: 520
    //theme.typography.fontWeightLight
  }
}));

function PersistentDrawerLeft(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  // const [open, setOpen] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [mapWarn, setMapWarn] = React.useState(false)//实时地图告警
  const [multiMapWarn, setMultiMapWarn] = React.useState(false)//九宫格告警 

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const handleDrawerOpen = () => {
    appStore.setOpen(true);
  };

  const handleDrawerClose = () => {
    appStore.setOpen(false);
  };

  React.useEffect(() => {
    Object.keys(menuStructure).map(item => {
      if (menuStructure[item] !== null) {
        menu.addNode(item)
      }
    })
    reaction(
      () => realtimeStore.violates.map(todo => todo.violate),
      () => {
        if (localStorage.getItem("call") === "noShow") {
          let violates = realtimeStore.realtime_violates.getEntries()
          if (props.location.pathname !== '/realtime_map' && violates.length > 0) {
            setMapWarn(true)
          } else {
            setMapWarn(false)
          }

          if (props.location.pathname !== '/multi_map' && violates.length > 0) {
            setMultiMapWarn(true)
          } else {
            setMultiMapWarn(false)
          }

          getAudioMap(violates.length)
        }
      })
    APIEndExt.get('/trackable_baseinfos_view').then(res_trackable => {
      mapFilterStore.setTrackableList(res_trackable.data)
    })

  }, [])
  React.useEffect(() => {
    if (localStorage.getItem("call") === "noShow") {
      let violates = realtimeStore.realtime_violates.getEntries()
      if (props.location.pathname !== '/realtime_map' && violates.length > 0) {
        setMapWarn(true)
      } else {
        setMapWarn(false)
      }

      if (props.location.pathname !== '/multi_map' && violates.length > 0) {
        setMultiMapWarn(true)
      } else {
        setMultiMapWarn(false)
      }


      getAudioMap(violates.length)
      if (props.location.pathname !== '/message_center') {
        appStore.setFallingWarnFilter()
      }
    }
  })


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: appStore.open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, appStore.open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title} style={{ color: 'white' }}>
            {env.REACT_APP_COMPANY ? env.REACT_APP_COMPANY : process.env.REACT_APP_COMPANY}
          </Typography>
          <Slot name="appbarHolder" />
          <div>
            {t('在线总人数：')}{realtimeStore.online}&nbsp;&nbsp;
            <Button
              onClick={() => {
                props.history.replace('/')
                window.location.reload()
              }}
              color="primary"
              variant="contained"
              disableElevation
              startIcon={<ExitToAppIcon />}
            >
              {t('退出')}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={appStore.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <AppMenu isMapWarn={mapWarn} isMultiMapWarn={multiMapWarn} />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: appStore.open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Suspense fallback={
          <div>
            <Skeleton variant="text" />
            <Skeleton variant="circle" width={40} height={40} />
            <Skeleton variant="rect" width={310} height={218} />
            <Skeleton variant="text" />
          </div>
        }>
          <Switch>
            <Route path="/video_follow">
              <VideoFollow />
            </Route>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/userandrole">
              <UserAndRole />
            </Route>
            <Route path="/org">
              <Org />
            </Route>
            <Route path="/tracobgroup">
              <TrackGroup />
            </Route>
            <Route path="/trackcategory">
              <TrackCategory />
            </Route>
            <Route path="/tracobj">
              <TrackableObj />
            </Route>
            <Route path="/trackbatch">
              <TrackBatch />
            </Route>
            <Route path="/importdata">
              <ImportData />
            </Route>
            <Route path="/project">
              <Project />
            </Route>
            <Route path="/labels">
              <Labels />
            </Route>
            <Route path="/caption">
              <Caption />  {/* 解说词 */}
            </Route>
            {/* 电子围栏 */}
            <Route path="/elecfence">
              <Elecfence />
            </Route>
            {/* 围栏类别 */}
            <Route path="/fencecategory">
              <FenceCategory />
            </Route>
            <Route path="/task_category">
              <TaskCategory />
            </Route>
            <Route path="/task_schedule">
              <TaskSchedule />
            </Route>
            <Route path="/task_object">
              <TaskObject />
            </Route>
            <Route path="/zone_violates">
              <Zoneviolates />
            </Route>
            <Route path="/zonecategory">
              <ZoneCategory />
            </Route>
            <Route path="/visitor">
              <Visitor />
            </Route>
            <Route path="/visitor_blacklist">
              <VisitorBlacklist />
            </Route>
            <Route path="/safety">
              <Safety />
            </Route>
            <Route path="/task_preform_define">
              <TaskPreformDefine />
            </Route>
            <Route path="/task_content">
              <TaskContent />
            </Route>
            <Route path="/task">
              <Task />
            </Route>
            <Route path="/task_history">
              <TaskHistory />
            </Route>
            <Route path="/task_map">
              <TaskMap />
            </Route>
            <Route path="/message_center">
              <MsgCenter />
            </Route>
            <Route path="/realtime_map">
              <RealtimeMap />
            </Route>
            <Route path="/three_map">
              <ThreeMap />
            </Route>
            <Route path="/multi_map">
              <MultiMap />
            </Route>

            <Route path="/track_playback">
              <TrackPlayback />
            </Route>
            <Route path='/travelreport'>
              <TravelReport />
            </Route>
            <Route path='/alarmreport'>
              <AlarmReport />
            </Route>
            <Route path='/syncdata'>
              <RdpDataInte />
            </Route>
            <Route path='/area_inout_report'>
              <AreaReport />
            </Route>
            <Route path='/elecfence_inout_report'>
              <ElecfenceReport />
            </Route>
            {/* 看板 */}
            <Route path='/dash_board'>
              <DashBoard />
            </Route>
            {/* 数据看板 */}
            <Route path='/data_board'>
              <DataBoard />
            </Route>
            <Route path='/alarm_area'>
              <AlarmArea />
            </Route>
            <Route path="/map_import">
              <MapImport />
            </Route>
            <Route path="/area_group">
              <AreaGroup />
            </Route>
            {/* 考勤点名 */}
            <Route path="/roll_call">
              <RollCall />
            </Route>
            <Route path="/pws_configure" >
              <PWSConfigure />
            </Route>
            {/* 导航 */}
            <Route path="/navigation">
              <Navigation />
            </Route>
            <Route path="/navgation_routes">
              <NavigationRoutes />
            </Route>
          </Switch>
        </Suspense>
      </main>
    </div >
  );
}



const AppMenuOri = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles()
  let menuStructureCopy = JSON.parse(JSON.stringify(menuStructure))
  const preProcess = (topNode) => {
    Object.keys(topNode).map(subNode => {
      if (topNode[subNode]) {
        preProcess(topNode[subNode])
      }
      if (appStore.userMenus.includes(subNode) === false
        && topNode[subNode] === null) {
        delete topNode[subNode]
      }
    })
  }

  const preProcess1 = (obj) => {
    Object.keys(obj).map(item => {
      if (isEmpty(obj[item]) && obj[item] !== null) {
        delete obj[item]
      }
    })
  }

  if (appStore.userMenus[0] !== 'ALL') {
    preProcess(menuStructureCopy)
    preProcess1(menuStructureCopy)
  }

  const renderMenu = (topNode, nested) =>
    Object.keys(topNode).map((subNode, index) => {
      return (
        <React.Fragment key={index}>

          {
            Menu_Disabled.findIndex(item => item === subNode) !== -1 ? <></> :
              allMenuActions[subNode] ?
                <ListItem
                  key={subNode}
                  className={nested ? classes.nested : ''}
                  button
                  component={Link}
                  to={`/${subNode}`}
                  style={{
                    animation:
                      allMenuActions[subNode]['label'] === t('实时地图') && props.isMapWarn ?
                        'changeFloor 1s infinite' : allMenuActions[subNode]['label'] === t('消息中心') && appStore.fallingWarn ?
                          'changeFloor 1s infinite' : allMenuActions[subNode]['label'] === t('九宫格') && props.isMultiMapWarn ? 'changeFloor 1s infinite' : ''
                  }}
                >
                  <ListItemIcon>{allMenuActions[subNode].icon} </ListItemIcon>
                  <ListItemText primary={allMenuActions[subNode].label} />

                </ListItem>
                :
                <ListItem
                  key={subNode}
                  button
                  onClick={() => menu.toggleNode(subNode)}
                  style={{
                    animation:
                      subNode === t('实时地图') && props.isMapWarn ? 'changeFloor 1s infinite' : ''
                  }}
                >
                  {
                    Menu_Disabled.findIndex(item => item === subNode) !== -1 ? <></> : <>
                      <ListItemIcon>
                        {menu.nodes[subNode] ? <ExpandMore /> : <ChevronRightOutlinedIcon />}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography className={classes.drawerParentLvl}>
                            {subNode}
                          </Typography>
                        }
                      />
                    </>
                  }

                </ListItem>
          }
          {
            topNode[subNode] !== null && !topNode[subNode].hasOwnProperty('icon') ?
              <Collapse in={menu.nodes[subNode]} timeout="auto" unmountOnExit>
                <List component="div"  >
                  {renderMenu(topNode[subNode], true)}
                </List>
              </Collapse>
              :
              null
          }
        </React.Fragment >
      )
    })

  return (
    <List component="nav">
      {renderMenu(menuStructureCopy)}
    </List>
  )
}

const AppMenu = observer(AppMenuOri)

function Home() {
  const steps = ["/itg_node", "/itg_trackableobject", "/itg_trackgroup", "/itg_zone", "/itg_project", "/itg_sublocation", "itg_floorplans"]
  useEffect(() => {
    // steps.map(item => {
    //   APIEndItg.get(item)
    // })
    mapDataInit()
  }, [])
  return (
    <div style={{ width: '40vw', margin: 'auto', marginTop: '6vh' }}>
      <CoverImage />
    </div>
  )
}
export default observer(PersistentDrawerLeft)
/*
*/