import { observable, action, toJS } from 'mobx'
import config from './config.json';

export const cameraStore = observable({
  web_rtc: null,
  setWebRtc: action(val => {
    cameraStore.web_rtc = val
  })
})

export function showCamera(camera_url) {
  let web_rtc = webRTC(camera_url, 'video-container');
  cameraStore.setWebRtc(web_rtc)
}
export function closeCamera() {
  if (cameraStore.web_rtc) {
    // console.log(cameraStore.web_rtc)
    cameraStore.web_rtc.then(res => {
      res.close && res.close();
    })
  }
}


export async function webRTC(streamName = null, elementName) {
  const suuid = streamName || config.client.defaultStream;
  let stream = new MediaStream();
  let connection = new RTCPeerConnection();
  connection.oniceconnectionstatechange = () => console.log('connection.iceConnectionState', connection);
  connection.onnegotiationneeded = async () => {
    const offer = await connection.createOffer();
    await connection.setLocalDescription(offer);
    const res = await fetch(`http://${window.location.hostname}${config.server.encoderPort}/stream/receiver/${suuid}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
      body: new URLSearchParams({
        suuid: `${suuid}`,
        data: `${btoa(connection.localDescription?.sdp || '')}`,
      }),
    });
    const data = res && res.ok ? await res.text() : '';
    if (data.length === 0) {
      console.log('cannot connect:', `http://${window.location.hostname}${config.server.encoderPort}`);
    } else {
      connection.setRemoteDescription(new RTCSessionDescription({
        type: 'answer',
        sdp: atob(data),
      }));
      console.log('negotiation start:', offer);
    }
  };
  connection.ontrack = (event) => {
    stream.addTrack(event.track);
    const video = (typeof elementName === 'string') ? document.getElementById(elementName) : elementName;
    // @ts-ignore
    if (video instanceof HTMLVideoElement) {
      video.srcObject = stream;
      video.onloadeddata = async () => console.log('resolution:', video.videoWidth, video.videoHeight);
    } else { console.log('element is not a video element:', elementName); }

    console.log('received track:', event.track, event.track.getSettings());
  };
  const res = await fetch(`http://${window.location.hostname}${config.server.encoderPort}/stream/codec/${suuid}`);
  const streams = res && res.ok ? await res.json() : [];
  if (streams.length === 0) console.log('received no streams');
  else console.log('received streams:', streams);
  for (const s of streams) {
    connection.addTransceiver(s.Type, { direction: 'sendrecv' });
  }

  let timer;
  const channel = connection.createDataChannel(suuid, { maxRetransmits: 10 });
  channel.onmessage = (e) => console.log('channel message:', channel.label, 'payload', e.data);
  channel.onerror = (e) => { console.log('chanchannelnel error:', channel.label, 'payload', e, 'timer', timer); clearInterval(timer); stream = null; connection = null }
  channel.onclose = () => { console.log('channel close', timer); clearInterval(timer); stream = null; connection = null }
  channel.onopen = () => {
    timer = setInterval(() => channel.send('ping'), 1000); // send ping becouse PION doesn't handle RTCSessionDescription.close()
    console.log('channel open', timer);
  };

  return connection;
}