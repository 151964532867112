
import { observable, action, toJS } from 'mobx' 
import { appStore, dsc, realtimeStore } from './../../appStore' 
import i18n from './../../i18n' 
import {countBy} from 'lodash'
import moment from 'moment'
import React from 'react'; 
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { AutoSizer, Column, Table } from 'react-virtualized'; 

export const dashBoradStore = observable({ 
  data: [],
  setData: action((val) => {
    dashBoradStore.data = val
  }),
  reloadFlag: false,
  setReloadFlag: action(val => {
    dashBoradStore.reloadFlag = val
  }),
  reloadAlarm: false,
  setReloadAlarm: action(val => {
    dashBoradStore.reloadAlarm = val
  }),

  module: {
    area_statis: true,
    alarm_statis: true,
    battery_statis:true
  },
  setModule: action((info, val) => {
    dashBoradStore.module[info] = val
  }),
  //  预警模块数据
  alert_list: [],
  setAddAlertList: action((val) => {
    dashBoradStore.alert_list.push(val)
  }),
  setResetAlertList: action(val => {
    dashBoradStore.alert_list = []
  }),
  alert_total: 0,
  setAlertTotal: action(val => {
    dashBoradStore.alert_total = val
  }),
  alert_num: {},
  setAlertNum: action(val => {
    dashBoradStore.alert_num = val
  }),
  alert_list_filter: [],
  setAddAlertListFilter: action((val) => {
    dashBoradStore.alert_list_filter = val
  }),
  battery_list:[],
  setBatteryList:action(val=>{
    dashBoradStore.battery_list=val
  }),
  reloadBattery: false,
  setReloadBattery: action(val => {
    dashBoradStore.reloadBattery = val
  }),
})

async function init_violates() {
  let violates = realtimeStore.realtime_violates.getEntries();

  for (var i = 0; i < violates.length; i++) {
    let record = dsc.record.getRecord(violates[i]);
    await record.whenReady();
    let data = record.get(); 
    add_alert(data); 
  }

  dashBoradStore.setAddAlertListFilter(dashBoradStore.alert_list)
  dashBoradStore.setAlertTotal(dashBoradStore.alert_list.length)
  dashBoradStore.setAlertNum(countBy(dashBoradStore.alert_list, "rule_name"))

}

let rules = {
  overstaffing: i18n.t("聚众"),
  understaffing: i18n.t("缺员"),
  detain: i18n.t("滞留"),
  halt: i18n.t("静止"),
  keep_in: i18n.t("禁出"),
  keep_out: i18n.t("禁入"),
}
//新增围栏预警
function add_alert(data) {

  let zone_info = appStore.data_list.zones.find(
    (item) => item.uid === data.rule_zone_uid
  );
  if (data.rule_name === "understaffing") {
    data = Object.assign(data, {
      sublocation_uid: zone_info.sublocation_uid,
    });
  }

  let floor_info = appStore.data_list.sublocations.find(
    (item) => item.uid === data.sublocation_uid
  );
  let testRes = appStore.data_list.baseinfos.findIndex((item) => item.uid === data.dev_id);

  dashBoradStore.setAddAlertList(
    Object.assign(data, {
      rule_name: data.rule_name,
      alarm_content: rules[data.rule_name],
      floor_name: `${floor_info ? floor_info.name : ''}`,
      zone_name: zone_info.name,
      person_name: testRes !== -1 ? appStore.data_list.baseinfos[testRes]["name"] : "",
      trackable_name: testRes !== -1 ? appStore.data_list.baseinfos[testRes]["trackable_name"] : '',
      ts: moment(data.ts).format("YYYY-M-D HH:mm:ss"),
    })
  );
}


const styles = (theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
    },
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
});


class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight, wordBreak: 'break-all' }} 
      >
        {cellData}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, columns, classes } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight }} 
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            gridStyle={{
              direction: 'inherit',
            }}
            headerHeight={headerHeight}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}
MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      // numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
};

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

export { init_violates, rules,VirtualizedTable }
