import React from 'react';
import ReactDOM from 'react-dom';
import './App.css' 

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Main from './Main';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom'
import { realtimeStore } from './appStore'
import 'mobx-react-lite/batchingForReactDom'
import { appStore, StoreProvider } from './appStore'
import './i18n'
import { SlotsProvider } from 'nano-slots';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
// import { orange, green } from '@material-ui/core/colors';


realtimeStore.setup()

// const theme = createMuiTheme({
//   status: {
//     danger: orange[500],
//   },
// });
const theme = createTheme({
//   palette: {
//     // primary: orange, 
//     primary: {
//       main: '#ff4400',
//     },
//     secondary: {
//       main: green[500],
//       light: '#0066ff',
//       // main: '#0044ff', 
//     },
//     text: {
//       // primary: '#ff4400'
//     }
//   },
});

/*
const fetcher = (...args) => 
  fetch(...args).then((res) => 
    res.json())*/

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <SlotsProvider>
      <StoreProvider store={appStore}>
        <Router>
          <Main />
        </Router>
      </StoreProvider>
    </SlotsProvider>
  </ThemeProvider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register();

/*
  <Provider store={notificationStore}>
    <SnackbarProvider preventDuplicate></SnackbarProvider>
    </SnackbarProvider>
  </Provider>*/
