import React from 'react'
import { APIEndRdp, APIEndExt, APIEndItg, APIFile } from './api'
import { observable, action, toJS, runInAction, } from 'mobx'
import { DeepstreamClient } from '@deepstream/client'
import { mapFilterStore, initObjData, zone_warning } from './components/RealtimeMap/mapFilterStore'
import i18n from './i18n'
import moment from 'moment';
import { types, applySnapshot } from 'mobx-state-tree'
import { threeMapStore, filter_data, tkos_init_data } from './components/RealtimeMap/ThreeMap/threeMapStore'
import { dashBoradStore } from './components/DashBoard/dashBoradStore'
import { trackPlaybackStore } from './components/TrackPlayback/trackPlaybackStore'
import { zoneStore } from './components/ElecFence/ZoneStore'
import {uniqBy} from 'lodash'
import {env} from './env'
//分析报表-- 区域进出
const InOutRecord = types.model({
  id: types.integer,
  obj_uid: types.string,
  card_name: types.string,
  user_name: types.maybeNull(types.string),
  dep_name: '',
  sublocation_name: types.string,
  enter_time: '',
  exit_time: types.maybeNull(types.string),
  sub_duration: types.maybeNull(types.string),
  distance: types.maybeNull(types.number),
  halt_time: types.maybeNull(types.number)
  })
  .actions(self => ({
    updateEXIT({ exit_time, duration, distance, halt_time }) {
      self.sub_duration = duration
      self.distance = distance
      self.halt_time = halt_time
    }
  }))

export const InOuts = types.model({
  xs: types.array(InOutRecord)
})
  .actions(self => ({
    addItem(item) {
      self.xs.unshift(InOutRecord.create(item))
    },
    updateEXIT(exit_tko) {
      let found = self.xs.find(item => item.obj_uid === exit_tko.obj_uid && item.id === exit_tko.id)
      found && found.updateEXIT(exit_tko)
    }
  }))

// 分析报表-- 电子围栏进出   

const InOutElecfence = types.model({
  zone_uid: types.string,//电子围栏uid
  dev_id: types.string,//设备id
  card_name: types.string,//标签
  zone_name: types.string,
  user_name: types.maybeNull(types.string),
  dep_name: '',
  db_tab_exit_id: types.maybeNull(types.number),//进出记录数据库ID
  db_tab_enter_id: types.maybeNull(types.number),
  enter_time: '',//进入时间
  exit_time: '',//离开时间
  sub_duration: types.maybeNull(types.string),//时长
  distance: types.maybeNull(types.number),//行程
  halt_time: types.maybeNull(types.number),//静止时长
  ts: types.maybeNull(types.string),

})
  .actions(self => ({
    updateEXIT({ exit_time, distance, halt_time, sub_duration }) {
      self.distance = distance
      self.halt_time = halt_time
      self.exit_time = exit_time
      self.sub_duration = sub_duration
      // console.log(self)
    }
  }))

export const InOuts_elecfence = types.model({
  xs: types.array(InOutElecfence)
})
  .actions(self => ({
    addItem(item) {
      self.xs.unshift(InOutElecfence.create(item))
    },
    updateEXIT(exit_tko) {
      let found = self.xs.find(item => item.db_tab_enter_id === exit_tko.db_tab_exit_id)
      found && found.updateEXIT(exit_tko)
    }
  }))



const DPSSvr = env.REACT_APP_DPS?env.REACT_APP_DPS:process.env.REACT_APP_DPS
export const dsc = new DeepstreamClient(DPSSvr)
export const enter_exit_data = InOuts.create()
export const enter_exit_elecfence = InOuts_elecfence.create()//电子围栏进出
// console.log(dsc)
dsc.on('connectionStateChanged', connectionState => {
  // will be called with 'RECONNECTING' and the subsequent intermediate connection states 
  console.log(connectionState)
})

dsc.on('error', (error, event, topic) =>
  console.log(error, event, topic)
)

// 消息中心--按钮消息
export const msgStore = observable({
  loading: false,
  setLoading: action(val => {
    msgStore.loading = val
  }),
  fallingDownMsgs: [],//跌落消息list
  setInitFalling: action((i, val) => {
    msgStore.fallingDownMsgs[i] = val
  }),
  setFallingDownMsg: action(payload => {
    msgStore.fallingDownMsgs = payload
  }),
  addFallingDownMsg: action(payload => {
    msgStore.setLoading(true)
    msgStore.fallingDownMsgs.unshift(payload)
    msgStore.setLoading(false)

  }),
  setFallingInfo: action((i, info, val) => {
    msgStore.fallingDownMsgs[i][info] = val
  }),
  fallingHistory: [],
  setFallingHistory: action(val => {
    msgStore.fallingHistory = val
  }),
  setFallingInfo_history: action((i, info, val) => {
    msgStore.fallingHistory[i][info] = val
  }),

  // clearFallingDownMsg: action(payload => {
  //   let index = msgStore.fallingDownMsgs.findIndex(item => item.uid === payload.uid)
  //   console.log(index)
  //   if (index !== -1) {
  //     msgStore.fallingDownMsgs.splice(index, 1)
  //     msgStore.fallingDownMsgs = [...msgStore.fallingDownMsgs]
  //   }
  // }),
  // ---------------------
  recvMsgs: [],//按钮消息list
  setInitrecvMsgs: action((i, val) => {
    msgStore.recvMsgs[i] = val
  }),
  addRecvMsg: action(payload => {
    msgStore.recvMsgs.unshift(payload)
  }),
  // clearRecvMsg: action(payload => {
  //   console.log(payload)
  //   let index = msgStore.recvMsgs.findIndex(item => item.uid === payload.uid)
  //   console.log(index)
  //   if (index !== -1) {
  //     msgStore.recvMsgs.splice(index, 1)
  //     msgStore.recvMsgs = [...msgStore.recvMsgs]
  //   }
  // }),
  recvHistory: [],
  setRecvHistory: action(val => {
    msgStore.recvHistory = val
  }),
  setRecvMsgsInfo: action((i, info, val) => {
    msgStore.recvMsgs[i][info] = val
  }),


  // --------------------
  sendMessage: null,
  msgtab: [{ name: i18n.t("跌落消息"), violate: false },
  { name: i18n.t("按钮消息"), violate: false },
  { name: i18n.t("发送按钮消息"), violate: false },
  { name: i18n.t("任务消息"), violate: false }],
  setMsgtab: action((i, info, val) => {
    msgStore.msgtab[i][info] = val
  }),
})

export const appStore = observable({
  open: true,
  setOpen: action(val => {
    appStore.open = val
  }),
  isAuth: null,
  setAuth: action(bool => {
    appStore.isAuth = bool
  }),
  fallingWarn: false,//消息中心--菜单闪烁
  setFallingWarn: action(payload => {
    appStore.fallingWarn = payload
  }),
  setFallingWarnFilter: action(payload => {
    let recvMsgs_filter = msgStore.recvMsgs.filter(item => item.rcv_ack == false)
    let falling_filter = msgStore.fallingDownMsgs.filter(item => item.rcv_ack == false)
    if (falling_filter.length > 0) {
      msgStore.setMsgtab(0, "violate", true)
    } else {
      msgStore.setMsgtab(0, "violate", false)
    }

    if (recvMsgs_filter.length > 0) {
      msgStore.setMsgtab(1, "violate", true)
    } else {
      msgStore.setMsgtab(1, "violate", false)
    }

    if (recvMsgs_filter.length > 0 || falling_filter.length > 0) {
      appStore.setFallingWarn(true)
      getAudio()
    } else {
      appStore.setFallingWarn(false)
      if (appStore.audio) {
        appStore.audio.pause()
      }
    }
  }),
  audio: null,
  setAudio: action(val => {
    appStore.audio = val
  }),

  userMenus: [],
  setUserMenus: action(payload => {
    appStore.userMenus = payload
  }),
  hasMenuItem: action(name => {
  }),
  projectid: '',
  setProject: action(function (payload) { 
    appStore.projectid = payload
    APIEndRdp.defaults.headers['X-User-Project'] = payload
  }),
  user_uid: '',
  setUserUid: action(payload => {
    appStore.user_uid = payload
  }),
  userEmail: '',
  setUserEmail: action(payload => {
    appStore.userEmail = payload
  }),
  userToken: '',
  setUserToken: action(payload => {
    appStore.userToken = payload
  }),
  tokenExpireTime: null,
  setTokenExpireTime: action(payload => {
    appStore.tokenExpireTime = payload
  }),
  username: null,
  setUsername: action(val => {
    appStore.username = val
  }),
  isDisplay: false,
  setIsDisplay: action(val => {
    appStore.isDisplay = val
  }),
  // 标签、围栏、楼层
  data_list: {
    baseinfos: [],
    zones: [],
    floors: [],
    sublocations: []
  },
  setDataList: action((info, val) => {
    // console.log(val)
    appStore.data_list[info] = val
  }),
  iconUrl: {},
  setIconUrl: action(val => {
    appStore.iconUrl = Object.assign(appStore.iconUrl, val)
  })
})

// 下载标签icon
export async function getIconUrl() {
  let res = await APIEndExt.get('/trackgroups?select=name,icon_name')
  res.data.map(item => {
    if (item.icon_name) {
      APIFile.get(`/trk_group_icon?file_name=${item.icon_name}`, { responseType: 'blob' }
      ).then(res_url => {
        let blob = new Blob([res_url.data]);
        let url = window.URL.createObjectURL(blob);
        appStore.setIconUrl({ [item.name]: url })
      })
    }
  })
}
/*
export const notificationStore = observable({
  notifications: [],
  enqueueSnackbar: action(note => {
    notificationStore.notifications.push({
      key: new Date().getTime() + Math.random(),
      ...note,
    })
  })
})*/

export const realtimeStore = observable({
  online: 0,
  setOnline: action(val => {
    realtimeStore.online = val
  }),
  msgs: [],
  violates: [],
  notifications: [],
  get missedMsgCount() {
    return realtimeStore.msgs.filter(item => item.msgAck === false).length
  },
  addViolate: action(function (aViolate) {
    realtimeStore.violates.push(aViolate)
    /*
    if (aViolate.violate === true) {
      notificationStore.enqueueSnackbar({
        message: '违规警告',
        options: {
          variant: 'Error',
        }
      })
    }*/
  }),
  removeViolate: action(function (aViolate) {
    let idx = realtimeStore.violates.findIndex(item => item.uniqueid === aViolate.uniqueid)
    realtimeStore.violates.splice(idx, 1)
  }),
  emptyViolates: action(function () {
    realtimeStore.violates.clear()
  }),
  modify: action(function (payload) {
    let idx = realtimeStore.violates.findIndex(item => item.uniqueid === payload.uniqueid)
    realtimeStore.violates[idx] = payload
    if (payload.violate === true) {
      /*
      notificationStore.enqueueSnackbar({
        message: '违规警告',
        options: {
          variant: 'Error',
        }
      })*/
    }
  }),
  realtime_violates: null,
  realtime_zoneinout: null,//视频进出
  fallings: null,//消息中心--跌落监测
  alarm_messages: null,//消息中心跌落+按钮消息
  liveTkos: null,//weizhi 
  realtime_countzoneinout: null,//分析报表--实时电子围栏进出
  unsub: action(() => realtimeStore.realtime_violates.discard()),
  setup: action(async () => { 
    await dsc.login({
      type: "webhook",
      user: "track_position",
      pwd: "whatever"
    }) 
    // 启动获得列表
    realtimeStore.realtime_violates = dsc.record.getList('zone_violates')
    realtimeStore.realtime_zoneinout = dsc.record.getList('zone_inouts')//视频进出
    realtimeStore.fallings = dsc.record.getList(`fallings`)//消息中心--跌落监测 
    realtimeStore.alarm_messages = dsc.record.getList(`alarm_messages`)
    realtimeStore.liveTkos = dsc.record.getList('live_tkos')//weizhi 
    realtimeStore.realtime_countzoneinout = dsc.record.getList('count_zone_inouts')//分析报表--实时电子围栏进出

    await realtimeStore.realtime_violates.whenReady()
    let violates = realtimeStore.realtime_violates.getEntries()
    // console.log(violates)

    await realtimeStore.alarm_messages.whenReady()
    let center_list = realtimeStore.alarm_messages.getEntries()
    center_list.map(async (item, index) => {
      let record = dsc.record.getRecord(item)
      await record.whenReady()
      // record.set('rcv_ack', false) 
      let data = record.get()
      if (data.type === "rdp_emergency") {
        // 按钮消息  
        if (!data.rcv_ack) {
          msgStore.addRecvMsg(data)
        }
      } else if (data.type === 'rdp_falling') {
        // 跌落消息  
        if (!data.rcv_ack) {
          msgStore.addFallingDownMsg(data)
        }
      }
    })

    await realtimeStore.liveTkos.whenReady()
    let obj_point_list = realtimeStore.liveTkos.getEntries()
    // console.log(obj_point_list)
    //在线总人数
    realtimeStore.setOnline(obj_point_list.length)
    //  定位对象---added
    realtimeStore.liveTkos.on('entry-added', async (entry) => {
      //console.log('定位对象--appstore-added')
      let record = dsc.record.getRecord(entry)
      await record.whenReady()
      let data = record.get()
      // console.log(data)
      await updateMapInfo()
      if (window.location.pathname === '/dash_board') {
        // console.log('看板在线人数')
        if (dashBoradStore.module.area_statis) {
          dashBoradStore.setReloadFlag(!dashBoradStore.reloadFlag)
        }

      }
    })
    realtimeStore.liveTkos.on('entry-removed', async (entry) => {
      // console.log('定位对象--appstore--clear')
      let record = dsc.record.getRecord(entry)
      await record.whenReady()
      let data = record.get()
      // console.log(data)
      await updateMapInfo()
      if (window.location.pathname === '/dash_board') {
        // console.log('看板在线人数')
        if (dashBoradStore.module.area_statis) {
          dashBoradStore.setReloadFlag(!dashBoradStore.reloadFlag)
        }
      }

    })

    dsc.event.subscribe('sublocation_change', data => {
      // console.log('区域更新')
      // console.log(data)
      let index = mapFilterStore.data.findIndex(item => item.uid === data.tkoid)
      if (index !== -1) {
        mapFilterStore.setDataViolate(index, 'slid', data.newid)
      }
      // 如果是实时地图界面，则执行
      if (window.location.pathname === '/realtime_map') {
        // console.log('实时地图区域更新')
        initObjData()
        floorNums(mapFilterStore.allFloorplans)
      } else if (window.location.pathname === '/three_map') {
        // console.log('3d区域更新')
        filter_data()
      } else if (window.location.pathname === '/dash_board') {
        // console.log('看板更新')
        if (dashBoradStore.module.area_statis) {//区域统计 
          dashBoradStore.setReloadFlag(!dashBoradStore.reloadFlag)
        }
      }

    })

    // ---------end-------
    // 消息中心
    realtimeStore.alarm_messages.on('entry-added', async (entry) => {
      let record = dsc.record.getRecord(entry)
      await record.whenReady()
      let data = record.get()
      if (data.type === "rdp_emergency") {
        // 按钮消息   
        msgStore.addRecvMsg(data)
      } else if (data.type === 'rdp_falling') {
        // 跌落消息  
        msgStore.addFallingDownMsg(data)
      }
      appStore.setFallingWarnFilter()
    })

    // ----------------
    Promise.all(violates.map(async item => {
      let record = dsc.record.getRecord(item)
      await record.whenReady()
      record.subscribe(data => {
        runInAction(() => {
          realtimeStore.modify(data)
        })
      })

      runInAction(() => {
        realtimeStore.addViolate(record.get())
      })
    }))

    // session 中更新列表
    realtimeStore.realtime_violates.on('entry-added', async (entry) => {
      // console.log('appstore 围栏告警')
      let record = dsc.record.getRecord(entry)
      await record.whenReady()
      runInAction(() => {
        realtimeStore.addViolate(record.get())
      })

      // 实时地图围栏
      let data = record.get()
      data && zone_warning(data, true)


      if (window.location.pathname === '/dash_board') {
        // console.log('看板更新')
        if (dashBoradStore.module.alarm_statis) {//告警统计 
          dashBoradStore.setReloadAlarm(!dashBoradStore.reloadAlarm)
        }
      }

    })

    realtimeStore.realtime_violates.on('entry-removed', async (entry) => {
      let record = dsc.record.getRecord(entry)
      await record.whenReady()
      runInAction(() => {
        realtimeStore.removeViolate(record.get())
      })
      // 实时地图围栏
      let data = record.get()
      data && zone_warning(data, false)


      if (window.location.pathname === '/dash_board') {
        // console.log('看板更新')
        if (dashBoradStore.module.alarm_statis) {//告警统计
          dashBoradStore.setResetAlertList()
          // console.log('移除告警哇')
          dashBoradStore.setReloadAlarm(!dashBoradStore.reloadAlarm)
        }
      }

    })


    let msgList = dsc.record.getList('msg_center')
    await msgList.whenReady()
    let entries = msgList.getEntries()
    Promise.all(entries.map(async item => {
      let record = dsc.record.getRecord(item)
      await record.whenReady()
      realtimeStore.msgs.push(record.get())
    }))
    msgList.on('entry-added', async (entry) => {
      let record = dsc.record.getRecord(entry)
      await record.whenReady()
      runInAction(() => {
        realtimeStore.msgs.push(record.get())
      })
    })
    // not use for now
    realtimeStore.realtime_violates.on('delete', () =>
      realtimeStore.emptyViolates()
    )
  })
})

export const mapStore = observable({
  player: null,
  setPlayer: action(val => {
    mapStore.player = val
  }),

})

const StoreContext = React.createContext()

export const StoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStore = () => React.useContext(StoreContext)

// 更新在线总人数以及实时地图标签
export async function updateObjOnline() {
  let obj_point_list = realtimeStore.liveTkos.getEntries()
  // console.log(obj_point_list.length) 
  realtimeStore.setOnline(obj_point_list.length)
  mapFilterStore.setRestData([])
  for (var i = 0; i < obj_point_list.length; i++) {
    let record = dsc.record.getRecord(obj_point_list[i])
    await record.whenReady()
    // console.log(record.get())
    mapFilterStore.setData(record.get())
  }
  // console.log(toJS(mapFilterStore.data))
}
// 实时地图tab切换楼层在线人数
export function floorNums(allFloor) {
  allFloor.map((floor, index) => {
    let nums = mapFilterStore.data.filter(item => item.slid === floor.sublocation_uid)
  //  console.log(toJS(mapFilterStore.data))
  //  console.log(nums)
    // mapFilterStore.setAllFloorWarn(index, 'onlineNum', _.uniqBy(nums, 'dev_id').length)
    mapFilterStore.setFloorInfo(index, 'onlineNum', uniqBy(nums, 'dev_id').length)
  })
}
// 更新地图信息
export async function updateMapInfo() {
  await updateObjOnline()//更新定位对象data
  console.log('更新定位对象')
  initObjData()//更新定位对象位置
  floorNums(mapFilterStore.allFloorplans)

  // 更新3dtkos  
  if (threeMapStore.tkoModel && threeMapStore.viewer && window.location.pathname === '/three_map') {
    await tkos_init_data()
    // console.log('3d区域更新===')
    filter_data()
  }

}

// 预警声音
export function getAudio() {
  if (!appStore.audio) {
    appStore.setAudio(new Audio('/alarm.mp3'))
    appStore.audio.play()
    appStore.audio.loop = 'loop'
  } else {
    appStore.audio.play()
    appStore.audio.loop = 'loop'
  }
}
// 对比时间大小
export const compareDate = (time) => {
  var date = moment(time).format('YYYY-M-D HH:mm')
  var d1 = new Date(date.replace(/\-/g, "/"));//预计还卡时间 
  var d2 = new Date();//当前时间   
  return d1 > d2 ? true : false;//true预计还卡时间小 false:当前时间大
}

// 规则
export const rules = {
  overstaffing: { label: i18n.t("聚众"), thresholdLabel: i18n.t("人数上限") },
  understaffing: { label: i18n.t("缺员"), thresholdLabel: i18n.t("人数下限") },
  detain: { label: i18n.t("滞留"), thresholdLabel: i18n.t("限时") },
  halt: { label: i18n.t("静止"), thresholdLabel: i18n.t("限时") },
  camtrack: { label: i18n.t("视频跟踪"), thresholdLabel: i18n.t("无") },
  keep_in: { label: i18n.t('禁出') },
  keep_out: { label: i18n.t('禁入') }
}



// 实时地图--楼层--围栏--更新

export async function mapDataInit() {
  let res_trackable = await APIEndExt.get('/trackable_baseinfos_view')
  mapFilterStore.setTrackableList(res_trackable.data)
  await updateObjOnline()
  let sublocations_data = await APIEndExt.get('/floorplans')
  //  楼层信息
  // sublocation中添加是否下载svg isUpdate：false 未下载  isUpdate：true  已下载
  sublocations_data.data = await mapData(sublocations_data.data)
  mapFilterStore.setAllFloorplans(sublocations_data.data)
  // mapFilterStore.setAllFloor(sublocations_data.data)
  if(sublocations_data.data.length>0){
    mapFilterStore.setFloorSubUid(sublocations_data.data[0]['sublocation_uid'])
    mapFilterStore.setAlignment(sublocations_data.data[0]['sublocation_name'])
    // mapFilterStore.setSublocationUid(sublocations_data.data[0]['uid'])
    threeMapStore.setSelectFloorUid(sublocations_data.data[0]['sublocation_uid'])
    mapFilterStore.setSelectSubuid(sublocations_data.data[0]['sublocation_uid'])
  }
 
  // mapFilterStore.setSelected_sub_uid_track(sublocations_data.data.sublocations[0]['uid'])
  // 轨迹回放
  trackPlaybackStore.setTrackSublocationSvg(sublocations_data.data[0])
  // let initfloor = floorplans_data.data.find(item => {
  //   return item.sublocation_uid === sublocations_data.data[0]['uid']
  // })
  threeMapStore.setSelectFloor(sublocations_data.data[0])
  mapFilterStore.setFloorplan(sublocations_data.data[0])
  // mapFilterStore.setFloorplan_track(sublocations_data.data[0])
  trackPlaybackStore.setSelectFloorInfo(sublocations_data.data[0])
  floorNums(sublocations_data.data)//tab切换在线人数 


  let res_zone = await APIEndExt.get('/zone_baseinfos_view')
  let resZone = res_zone.data.filter(item => {
    return item.active === true
  })
  mapFilterStore.setZoneAll(resZone)
  let zoneData = resZone.filter(item => { return item.sublocation_uid === sublocations_data.data[0]['sublocation_uid'] })
  mapFilterStore.setZoneData(zoneData)

}

// 更新svg图以及3d数据
export async function mapData(sublocations, format) {
  let sublocations_data_EndExt = await APIEndExt.get('/sublocations')
  // let sublocations_data_EndExt = await APIEndExt.get('/floorplans')

  let data = sublocations.map(item => {
    let floorId = sublocations_data_EndExt.data.findIndex(every => every.uid === item.sublocation_uid)
    let update = mapFilterStore.allFloorplans.findIndex(every => every.sublocation_uid === item.sublocation_uid)
    return Object.assign(item, {
      isUpdate: update != -1 ? sublocations_data_EndExt.data[floorId]['twod_map_file_name'] !== mapFilterStore.allFloorplans[update]['twod_map_file_name'] ? false : mapFilterStore.allFloorplans[update]['isUpdate'] : false,
      twod_map_svg: update != -1 ? mapFilterStore.allFloorplans[update]['twod_map_svg'] : null,
      twod_map_file_name: floorId != -1 ? sublocations_data_EndExt.data[floorId]['twod_map_file_name'] : null,
      isUpdate_threed: update != -1 ? sublocations_data_EndExt.data[floorId]['threed_map_file_name'] !== mapFilterStore.allFloorplans[update]['threed_map_file_name'] ? false : mapFilterStore.allFloorplans[update]['isUpdate_threed'] : false,
      threed_map_svg: update != -1 ? mapFilterStore.allFloorplans[update]['threed_map_svg'] : null,
      threed_map_file_name: floorId != -1 ? sublocations_data_EndExt.data[floorId]['threed_map_file_name'] : null
    })
  })
  return data
}

// 更新实时地图围栏信息 

export async function updateRealTimeMapZone() {
  // await APIEndItg.get('/itg_zone')
  let res_zone = await APIEndExt.get('/zone_baseinfos_view')
  let resZone = res_zone.data.filter(item => {
    return item.active === true
  })
  mapFilterStore.setZoneAll(resZone)
}


export let Coordinate_Origin =env.REACT_APP_Coordinate_Origin?env.REACT_APP_Coordinate_Origin:process.env.REACT_APP_Coordinate_Origin//位置数据 center为红点数据
// 坐标转换
export function coordinate_calculatino({ module, defineScale, domains, vertical, position_direction, position_direction_rdp, position_info, type }) {
  switch (module) {
    case 'realTimeMap'://实时地图 
      let scale
      if (Coordinate_Origin === 'topLeft') {//位置信息为左上角，不需要转换
        scale = defineScale
      } else {
        scale = (x) => defineScale(getRealCord(x, domains, vertical))
      }
      return scale
      break;
    case 'zoneDesign'://围栏设计
      let position
      if (Coordinate_Origin === 'topLeft') {
        // 绘制图位置左下角为0,0 需转为左上角0,0 
        if (position_direction === 'toLeftTop') {
          position = getPosition(position_info, "toLeftTop")
        } else if (position_direction = "toLeftBottom") {//转为左下角 
          position = getPosition(position_info, "toLeftBottom")
        }
      } else {//红点数据center 需转为左上角0,0
        //  console.log('hongdian')
        if (position_direction_rdp === 'toLeft') {
          //  console.log('toLeft')
          position = toLeft(position_info)
        } else if (position_direction_rdp = "toCenter") {
          // console.log('toCenter')
          position = toCenter(position_info)

        }
      }
      return position
      break;
    default:
      return ''
      break;
  }

}



function getRealCord(value, dimValue, vertical) {
  if (vertical === false) {
    if (value < 0) {
      return (dimValue / 2 - Math.abs(value))
    } else {
      return dimValue / 2 + value
    }
  } else {
    if (value < 0) {
      return dimValue / 2 + Math.abs(value)
    } else {
      return dimValue / 2 - value
    }
  }
  return dimValue
}

// 围栏设计
function getPosition(info, direction) {
  let position = []
  info.map(item => {
    if (direction === "toLeftTop") {//提交
      position.push({
        x: item[0] * zoneStore.image_scale * 100,
        y: (zoneStore.baseMapHeightHalf * 2 - item[1]) * zoneStore.image_scale * 100
      })
    } else { //转为左下角 展示
      position.push([item.x / zoneStore.image_scale / 100, zoneStore.baseMapHeightHalf * 2 - item.y / zoneStore.image_scale / 100])
    }

  })
  return position
}

// 电子围栏绘制围栏提交
export function toCenter(info) {
  let position = []
  info.map(item => {
    let x_info = item[0] > zoneStore.baseMapWidthHalf ? item[0] - zoneStore.baseMapWidthHalf : -(zoneStore.baseMapWidthHalf - item[0])
    let y_info = item[1] > zoneStore.baseMapHeightHalf ? item[1] - zoneStore.baseMapHeightHalf : item[1] - zoneStore.baseMapHeightHalf
    position.push({
      x: (x_info + zoneStore.current_floor.image_offset_x) * zoneStore.image_scale * 100,
      y: (y_info + zoneStore.current_floor.image_offset_y) * zoneStore.image_scale * 100
    })
  })
  return position
}

//电子围栏数据渲染
export function toLeft(info) {
  return info.map(item => {
    let x_info = (item.x / zoneStore.image_scale / 100) + zoneStore.baseMapWidthHalf + (-zoneStore.current_floor.image_offset_x)
    let y_info = (item.y / zoneStore.image_scale / 100) + zoneStore.baseMapHeightHalf + (-zoneStore.current_floor.image_offset_y)

    return [x_info, y_info]
  })
}

