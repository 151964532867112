import { observable, action } from "mobx";
import { mapFilterStore, downLoadSvg } from '../RealtimeMap/mapFilterStore'
import { realtimeStore, dsc } from '../../appStore'
import { APIEndExt } from '../../api'
import {map as lmap, uniqBy} from 'lodash' 
export const multiMapStore = observable({
    tags: [],
    setTags: action(val => {
        multiMapStore.tags = val
    }),
    mapList: [],
    setReset: action(val => {
        multiMapStore.mapList = []
    }),
    setMapList: action(val => {
        multiMapStore.mapList.push(val)
    }),
    setMapInfo: action((index, info, val) => {
        multiMapStore.mapList[index][info] = val
    }),
    setMapData: action((index_slid, index_data, val) => {
        multiMapStore.mapList[index_slid]['data'][index_data] = val
    }) 
})

async function mapInfoUpdate(tags) {
    let maps = await APIEndExt.post("/rpc/get_foorplans_by_tag", { "tag_name": tags })
    multiMapStore.setReset()
    // console.log(toJS(mapFilterStore.data)) 
    maps.data.map(async item => {  
        let floor_index = mapFilterStore.allFloorplans.findIndex(floor => item.sublocation_uid === floor.sublocation_uid)
        await downLoadSvg(floor_index, 'map') 
        // console.log(Object.assign(item, mapFilterStore.allFloorplans[floor_index])) 
        let zone_data = mapFilterStore.zoneAll.filter(zone => item.sublocation_uid === zone.sublocation_uid && zone.active === true)
      
        let data = mapFilterStore.data.filter(obj => item.sublocation_uid === obj.slid)
       
        multiMapStore.setMapList(Object.assign(item, mapFilterStore.allFloorplans[floor_index], { zone_data: zone_data, data: data }))
    })

}

function multiMapObjUpdate() {
    let uids = lmap(multiMapStore.mapList, "sublocation_uid")
    // 定位对象点位移动更新
    let obj_point = realtimeStore.liveTkos.getEntries()
    obj_point.map(async item => {
        let record = dsc.record.getRecord(item)
        await record.whenReady()
        record.subscribe(obj_info => {
            if (uids.includes(obj_info.slid)) {
                let map_index = multiMapStore.mapList.findIndex(item => item.sublocation_uid === obj_info.slid)
                // data中标签索引
                let testRes = map_index !== -1 ? multiMapStore.mapList[map_index].data.findIndex(item => item.dev_id === obj_info.dev_id) : -1
                if (testRes !== -1) {
                    let obj = Object.assign(multiMapStore.mapList[map_index].data[testRes], obj_info)
                    multiMapStore.setMapData(map_index, testRes, obj)
                    initObjData(multiMapStore.mapList[map_index])
                }
            }
            if (window.location.pathname !== '/multi_map') {
                record.discard()
            }
        })
    })
}

function initObjData(filter_data) {
    let updatableChart = filter_data.updatableChart
    // 九宫格--标签位置更新
    if (window.location.pathname === '/multi_map' && updatableChart) {
        updatableChart.data(uniqBy(filter_data['data'], 'dev_id'))
    }
}

export { mapInfoUpdate, multiMapObjUpdate }
